import { createSelector } from "reselect";
import { augmentMove } from "../../../helpers/moves";
import { makeUrlFromMoveName } from "../../../helpers/pixlPets";
import { getMoves, getRarity } from "../../data/selectors/commonSelectors";
import { getFilters, getSelectedMoves } from "./commonSelectors";

export const getMovesToDisplay = createSelector(
  [getMoves, getFilters, getRarity],
  (moves, filters, rarity) => {
    if (moves == null) {
      return undefined;
    }
    const rarityFilters = filters.rarity.map((el) => el.value);
    const elementFilters = filters.element.map((el) => el.value);

    return moves
      .filter(
        (m) =>
          (m.name.toLowerCase().includes(filters.searchText) ||
            filters.searchText === "") &&
          (elementFilters.includes(m.element) || elementFilters.length === 0) &&
          (rarityFilters.includes(m.rarity) || rarityFilters.length === 0)
      )
      .map((move) => {
        let opensea = makeUrlFromMoveName(move.name);

        return {
          ...move,
          opensea,
          rarityPercent:
            "Moves" in rarity ? rarity.Moves[move.name] : undefined,
        };
      });
  }
);

export const getSelectedMoveData = createSelector(
  [getSelectedMoves, getMoves],
  (selectedMoves, allMoves) => {
    return selectedMoves.map((move) => augmentMove(move, allMoves));
  }
);
