import * as types from "./moveExplorerActionTypes";
import produce from "immer";

export const initialFilters = { searchText: "", rarity: [], element: [] };

export const initialState = {
  selectedMoves: [],
  filters: initialFilters,
};

export const moveReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.MOVE_EXPLORER_UPDATE_FILTERS:
        draft.filters = action.filters;
        break;
      case types.MOVE_EXPLORER_UPDATE_SELECTED_MOVES:
        draft.selectedMoves = action.selectedMoves;
        break;
      case types.MOVE_EXPLORER_CLEAR_FILTERS:
        draft.filters = initialFilters;
        break;

      default:
        break;
    }
  });

export default moveReducer;
