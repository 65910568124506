import { Stack } from "@mui/material";
import React from "react";
import Card from "../../../components/PixlPet/Labels/Card";
import Deity from "../../../components/PixlPet/Labels/Deity";
import Egg from "../../../components/PixlPet/Labels/Egg";
import Element from "../../../components/PixlPet/Labels/Element";
import TraitCount from "../../../components/PixlPet/Labels/TraitCount";

const Labels = ({ element, traitCount, card, unhatched, deity }) => {
  return (
    <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
      {element != null && <Element element={element} />}
      {traitCount != null && <TraitCount traitCount={traitCount} />}
      {card != null && <Card card={card} />}
      {unhatched && <Egg />}
      {deity && <Deity />}
    </Stack>
  );
};

export default Labels;
