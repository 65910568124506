import * as types from "./searchDialogActionTypes";
import produce from "immer";
import {
  initialSearchDialogState,
  operationsWithPageReset,
} from "../../components/SearchDialog/SearchDialog";

export const initialState = {
  moveExplorer: initialSearchDialogState,
  inspector: initialSearchDialogState,
};

export const searchDialogReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // Update dialog properties
      case types.SEARCH_DIALOG_UPDATE:
        if (
          action.component in draft &&
          action.key in draft[action.component]
        ) {
          draft[action.component][action.key] = action.value;

          if (operationsWithPageReset.includes(action.key)) {
            draft[action.component].page = 1;
          }
        }
        break;

      // Fetch Pixl Pet Prices
      case types.SEARCH_DIALOG_FETCH_PIXL_PET_PRICES_STARTED:
        if (action.component in draft) {
          draft[action.component].fetchingPixlPetPrices = true;
          draft[action.component].fetchingPixlPetPricesFailed = false;
        }
        break;
      case types.SEARCH_DIALOG_FETCH_PIXL_PET_PRICES_SUCCEEDED:
        if (action.component in draft) {
          draft[action.component].fetchingPixlPetPrices = false;
        }
        break;
      case types.SEARCH_DIALOG_FETCH_PIXL_PET_PRICES_FAILED:
        if (action.component in draft) {
          draft[action.component].fetchingPixlPetPrices = false;
          draft[action.component].fetchingPixlPetPricesFailed = true;
        }
        break;

      // Fetch All Pixl Pet Prices
      case types.SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES_STARTED:
        if (action.component in draft) {
          draft[action.component].fetchingAllPixlPetPrices = true;
        }
        break;
      case types.SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES_SUCCEEDED:
        if (action.component in draft) {
          draft[action.component].fetchingAllPixlPetPrices = false;
        }
        break;
      case types.SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES_FAILED:
        if (action.component in draft) {
          draft[action.component].fetchingAllPixlPetPrices = false;
        }
        break;

      default:
        break;
    }
  });

export default searchDialogReducer;
