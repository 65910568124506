import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { opensea } from "../../../icons";

const OpenSeaButton = ({ url }) => {
  return (
    <Tooltip title="View on OpenSea">
      <IconButton
        href={url}
        target="_blank"
        sx={{
          width: 40,
          opacity: 0.5,
          "&:hover": {
            opacity: 1,
            transform: "scale(1.1)",
          },
        }}
      >
        {opensea()}
      </IconButton>
    </Tooltip>
  );
};

export default OpenSeaButton;
