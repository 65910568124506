import React from "react";
import { Stack, Typography } from "@mui/material";

const Price = ({ pet }) => {
  return (
    <Stack
      direction="row"
      gap={1}
      alignItems="center"
      sx={{
        img: {
          width: 14,
          height: "auto",
          filter: "brightness(3)",
        },
      }}
    >
      {pet?.sellOrderDetails?.currencyImageUrl && (
        <img
          src={pet?.sellOrderDetails?.currencyImageUrl}
          alt={pet?.sellOrderDetails?.currency}
        />
      )}
      <Typography
        variant="span"
        fontWeight={700}
        fontSize={24}
        lineHeight={1}
        color="text.white"
        sx={{ position: "relative", top: "1px" }}
      >
        {pet?.sellOrderDetails?.amount}
      </Typography>
      <Typography
        variant="span"
        fontWeight={600}
        fontSize={16}
        lineHeight={1}
        color="text.muted"
        sx={{ position: "relative", top: "3px" }}
      >
        {pet?.sellOrderDetails?.usdAmount != null &&
          `$${pet?.sellOrderDetails?.usdAmount.toLocaleString()}`}
      </Typography>
    </Stack>
  );
};

export default Price;
