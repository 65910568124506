import React from "react";
import { displayPercent } from "../../../../helpers/display";
import { Typography } from "@mui/material";
import { addAlphaToHex } from "../../../../helpers/colors";

const Percentage = ({ percentage, style }) => {
  return percentage != null ? (
    <Typography
      fontSize={12}
      className="percentage"
      textAlign="right"
      sx={{
        transition: "0.2s color ease",
        color: addAlphaToHex("#ffffff", 0.5),
        minWidth: 42,
        ...style,
      }}
    >
      {displayPercent(percentage)}
    </Typography>
  ) : null;
};

export default Percentage;
