import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";

const Name = ({ name }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="h2"
      sx={{
        [theme.breakpoints.down("sm")]: {
          fontSize: "2.2rem",
        },
      }}
    >
      {name}
    </Typography>
  );
};

export default Name;
