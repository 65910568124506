import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ id }) => {
  const title = id
    ? `#${id} Pixl Pet | Pixlverse.Tech`
    : "Pixl Pet Inspector | Pixlverse.Tech";
  const description =
    "Enter a Pixl Pet's ID to view its detailed profile, including moves and trait rarity.";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
    </>
  );
};

export default SEO;
