export const PIXLVERSE_TECH_COMPANY_NAME = "Pixlverse Tech";
export const PIXLVERSE_TECH_CONTACT_EMAIL = "info@pixlverse.tech";
export const PIXLVERSE_TECH_URL = "https://www.pixlverse.tech";

export const TRIBALLY_TAGLINE = "Powered By Tribally Games";

export const PIXLVERSE_TECH_TWITTER = "https://twitter.com/PixlverseTech";
export const PIXLVERSE_TECH_API = "https://tribally.xyz/pixlverse";
// export const PIXLVERSE_TECH_API = "http://localhost:3001/pixlverse";
export const OPENSEA_API_KEY = "d4863fc015c1448bb9bfba0a7e9d6c8f";

export const OPENSEA_API_ORDERS_ENDPOINT =
  "https://api.opensea.io/wyvern/v1/orders";

export const PIXLVERSE_API = "https://www.thepixlverse.io";
export const PIXL_PETS_CONTRACT_ADDRESS =
  "0x4e76c23fe2a4e37b5e07b5625e17098baab86c18";

export const ELEMENTS = ["Fire", "Water", "Air", "Earth"];
export const RARITY_TIERS = [1, 2, 3, 4, 5];

export const TRAIT_TYPES = [
  "Element",
  "Card",
  "Body",
  "Eyes",
  "Mouth",
  "Egg",
  "Moves",
  "Accessories",
  "Backs",
  "Face Details",
  "Glasses",
  "Hats",
  "Tails",
  "Wings",
  "Deity",
];
export const BASE_TRAIT_TYPES = [
  "Element",
  "Card",
  "Body",
  "Eyes",
  "Mouth",
  "Egg",
];
export const BONUS_TRAIT_TYPES = [
  "Accessories",
  "Backs",
  "Face Details",
  "Glasses",
  "Hats",
  "Tails",
  "Wings",
  "Deity",
];
export const WEIRD_CHARACTER_MAPPING = {
  "Original Œî": "Original Δ",
  "Original Œì": "Original Γ",
  "Original Œí": "Original Β",
  "Original Œë": "Original Α",
  "Variant Eyes (Œí)": "Variant Eyes (Β)",
  "Variant Eyes (Œë)": "Variant Eyes (Α)",
  "Variant Mouth (Œë)": "Variant Mouth (Α)",
  "Variant Mouth (Œí)": "Variant Mouth (Β)",
};

export const TOTAL_PIXL_PETS = 15000;
