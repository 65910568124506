import React from "react";
import {
  Button,
  Stack,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import Dropdown from "../../components/Dropdown/Dropdown";
import {
  SEARCH_DIALOG_BUY_NOW,
  SEARCH_DIALOG_SHOW_TRAITS,
  SEARCH_DIALOG_SORT,
} from "./SearchDialog";

const Controls = ({ searchDialog, updateSearchDialog }) => {
  const handleUpdateBuyNow = () => {
    updateSearchDialog(SEARCH_DIALOG_BUY_NOW, !searchDialog.buyNow);
  };

  const handleUpdateShowTraits = () => {
    updateSearchDialog(SEARCH_DIALOG_SHOW_TRAITS, !searchDialog.showTraits);
  };

  const handleUpdateSort = (newSort) => {
    updateSearchDialog(SEARCH_DIALOG_SORT, newSort);
  };

  return (
    <Stack gap={2} direction="row" alignItems="center" flexWrap="wrap">
      <FormControlLabel
        control={
          <Checkbox
            checked={searchDialog.buyNow}
            onChange={handleUpdateBuyNow}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={<CheckboxLabel>Buy Now</CheckboxLabel>}
      />

      <Button
        variant="text"
        startIcon={searchDialog.showTraits ? <IoMdEyeOff /> : <IoMdEye />}
        size="small"
        onClick={handleUpdateShowTraits}
        sx={{ minWidth: 133, height: 40 }}
      >
        {searchDialog.showTraits ? "Hide Traits" : "Show Traits"}
      </Button>

      {/* <PriceInput
        value={searchDialog.minPrice}
        updateValue={handleUpdateMinPrice}
        label="Min Price"
      />
      <PriceInput
        value={searchDialog.maxPrice}
        updateValue={handleUpdateMinPrice}
        label="Max Price"
      /> */}

      <Dropdown
        size="small"
        multiple={false}
        label="Sort"
        value={searchDialog.sort}
        updateValue={handleUpdateSort}
        options={[
          { label: "Lowest ID", value: "id-asc" },
          { label: "Highest ID", value: "id-desc" },
          { label: "Least Traits", value: "traitCount-asc" },
          { label: "Most Traits", value: "traitCount-desc" },
          { label: "Lowest Price", value: "price-asc" },
          { label: "Highest Price", value: "price-desc" },
        ]}
        style={{
          minWidth: "auto",
        }}
      />
    </Stack>
  );
};

// const PriceInput = ({ value, updateValue, label }) => {
//   return (
//     <TextField
//       value={value}
//       onChange={updateValue}
//       variant="outlined"
//       label={label}
//       size="small"
//       type="number"
//       InputProps={{
//         startAdornment: <InputAdornment position="start">Ξ</InputAdornment>,
//         inputProps: {
//           min: 0,
//           step: ".01",
//         },
//       }}
//       sx={{ width: "120px", minWidth: "120px" }}
//     />
//   );
// };

const CheckboxLabel = ({ children }) => (
  <Typography fontSize={17}>{children}</Typography>
);

export default Controls;
