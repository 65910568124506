import React from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { pluralize } from "../../../helpers/display";

const TraitCount = ({ traitCount, pixlPet, width }) => {
  const theme = useTheme();

  const getTop = () => {
    if (pixlPet.isDeity) {
      if (pixlPet.id === 5641) {
        return "0.5%";
      }
      return "3.5%";
    }
    return "3%";
  };
  const getRight = () => {
    if (pixlPet.isDeity) {
      if (pixlPet.id === 5641) {
        return "0%";
      }
      return "3.5%";
    }
    return "5%";
  };

  return (
    <Tooltip title={pluralize("trait", traitCount)} placement="left">
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          position: "absolute",
          right: getRight(),
          top: getTop(),
          width: `${(20 / 200) * width}px`,
          height: `${(20 / 200) * width}px`,
          border: `${(1 / 200) * width}px solid ${
            theme.palette.colors.black900
          }`,
          borderRadius: `${(4 / 200) * width}px`,
          userSelect: "none",
          background:
            "linear-gradient(127deg, #fffae1 0%, #F4E896 52.6%, #c19f13 100%)",
        }}
      >
        <Typography
          fontSize={(11 / 200) * width}
          fontWeight={700}
          sx={{
            lineHeight: 1,
            position: "relative",
            top: `${(1 / 200) * width}px`,
          }}
          color="text.dark"
        >
          {traitCount}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default TraitCount;
