import { Stack, Typography } from "@mui/material";
import React from "react";
import { pluralize } from "../../../helpers/display";
import { labelStyles, labelFontStyles } from "./commonStyles";

const TraitCount = ({ traitCount }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ ...labelStyles, border: "1px solid #fff" }}
    >
      <Typography
        variant="span"
        color="text.bright"
        fontWeight={700}
        sx={labelFontStyles}
      >
        {pluralize("trait", traitCount)}
      </Typography>
    </Stack>
  );
};

export default TraitCount;
