import * as types from "./dataActionTypes";
import * as actions from "./dataActions";
import { takeEvery, put, call, retry, fork, select } from "redux-saga/effects";
import { calculateRarity, petIsValid } from "../../helpers/pixlPets";
import {
  requestPixlPets,
  requestPixlPetOfficial,
  requestAddPixlPetToDatabase,
} from "../api";
import {
  getPixlPets,
  getPixlPetsLastFetched,
} from "./selectors/commonSelectors";
import moment from "moment";
import { cleanPet, petShouldBeAddedToDatabase } from "../../helpers/database";

export const RETRY_TIMES = 3;
export const RETRY_DELAY = 500;

export const dataIsStale = (lastFetched) => {
  const staleThreshold = moment().unix() - 600;

  return lastFetched < staleThreshold;
};

export function* fetchAllPixlPets() {
  try {
    const lastFetched = yield select(getPixlPetsLastFetched);
    const isStale = yield call(dataIsStale, lastFetched);

    if (isStale) {
      yield put(actions.fetchPixlPetsStarted());

      const pixlPets = yield retry(RETRY_TIMES, RETRY_DELAY, requestPixlPets);

      const rarityData = yield call(calculateRarity, pixlPets);

      yield put(actions.fetchPixlPetsSucceeded(pixlPets, rarityData));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.fetchPixlPetsFailed());
  }
}

export function* fetchPixlPet(id, callbackSuccess, callbackFailure) {
  try {
    const pet = yield retry(
      RETRY_TIMES,
      RETRY_DELAY,
      requestPixlPetOfficial,
      id
    );

    // Pet has all attributes
    if (petIsValid(pet)) {
      // Create payload
      const payload = yield call(cleanPet, pet);

      // Add pet to local data
      yield put(actions.fetchPixlPetSucceeded(payload));

      // Callback
      yield put(callbackSuccess(payload));

      if (petShouldBeAddedToDatabase(payload)) {
        // Recalculate rarity
        const pixlPets = yield select(getPixlPets);
        const rarity = yield call(calculateRarity, [...pixlPets, payload]);

        // Update rarity in state
        yield put(actions.updateRarity(rarity));

        // Post pet to db
        yield fork(requestAddPixlPetToDatabase, payload);
      }
    }
    // Pet is malformed
    else {
      yield put(callbackFailure());
    }
  } catch (e) {
    // Callback
    yield put(callbackFailure());
  }
}

// export function* fetchPixlPetPrices(action) {
//   try {
//     const now = moment().unix();
//     const staleThreshold = now - 600;

//     const prices = yield select(getPixlPetPrices);
//     const staleIds = action.ids.filter((id) => {
//       if (
//         !(id in prices) ||
//         (id in prices && prices[id].priceFetched < staleThreshold)
//       ) {
//         return true;
//       }
//       return false;
//     });

//     const chunkedStaleIds = chunk(staleIds, 30);

//     for (const staleIds of chunkedStaleIds) {
//       const data = yield retry(
//         RETRY_TIMES,
//         1000,
//         requestPixlPetPrices,
//         staleIds
//       );

//       const extractedPrices = extractPixlPetPriceData(data.orders, staleIds);

//       yield put(actions.fetchPixlPetPricesSucceeded(extractedPrices));
//       yield delay(1000);
//     }
//   } catch (e) {
//     console.log(e);
//   }
// }

export default function* dataSaga() {
  yield takeEvery(types.DATA_FETCH_PIXL_PETS, fetchAllPixlPets);
  // yield takeEvery(types.DATA_FETCH_PIXL_PET_PRICES, fetchPixlPetPrices);
}
