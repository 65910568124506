import React from "react";
import { Button, Stack } from "@mui/material";
import { connect } from "react-redux";
import {
  updateFilters,
  clearFilters,
} from "../../../store/moveExplorer/moveExplorerActions";
import { ELEMENTS } from "../../../config";
import Dropdown from "./Dropdown";
import Search from "./Search";

const Controls = ({ filters, updateFilters, clearFilters }) => {
  const handleUpdateFilters = (newFilters) => {
    updateFilters({ ...filters, ...newFilters });
  };

  const handleUpdateDropdown = (property) => {
    return (newVal) =>
      handleUpdateFilters({
        [property]: newVal,
      });
  };

  const handleUpdateSearch = (searchText) => {
    handleUpdateFilters({ searchText: searchText.toLowerCase() });
  };

  return (
    <Stack
      gap={2}
      direction={{ xs: "column", sm: "column", md: "row" }}
      alignItems={{ xs: "flex-start", sm: "flex-start", md: "center" }}
    >
      <Search
        label="Search moves"
        value={filters.searchText}
        updateValue={handleUpdateSearch}
      />
      <Dropdown
        label="Element"
        value={filters.element}
        updateValue={handleUpdateDropdown("element")}
        options={ELEMENTS.map((e) => ({ value: e }))}
      />
      <Dropdown
        label="Rarity"
        value={filters.rarity}
        updateValue={handleUpdateDropdown("rarity")}
        options={[
          { value: 1, suffix: "(lowest)" },
          { value: 2 },
          { value: 3 },
          { value: 4 },
          { value: 5, suffix: "(highest)" },
        ]}
      />
      <Button variant="contained" onClick={clearFilters} sx={{ height: 56 }}>
        Clear Filters
      </Button>
    </Stack>
  );
};

const mapDispatchToProps = { updateFilters, clearFilters };

const mapStateToProps = (state) => ({
  filters: state.moveExplorer.filters,
});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
