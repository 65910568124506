import React from "react";
import { Chip } from "@mui/material";
import { useTheme } from "@mui/system";

const SelectedMoves = ({ selectedMoves, updateSelectedMoves }) => {
  const theme = useTheme();

  const handleDelete = (name) => {
    const filtered = selectedMoves
      .filter((move) => move.name !== name)
      .map((move) => move.name);
    updateSelectedMoves(filtered);
  };

  return selectedMoves.map((move, i) => {
    return (
      <Chip
        label={`${move.name} (${move.rarity})`}
        key={`ITEM_${i}`}
        onDelete={() => handleDelete(move.name)}
        sx={{
          color: theme.palette.colors.white,
          backgroundColor: theme.palette.colors.black900,
          border: `3px solid ${
            theme.palette.colors.game[move.element.toLowerCase()]
          }`,
          fontSize: 14,
          "& .MuiChip-deleteIcon": {
            color: theme.palette.colors.white,
            "&:hover": {
              color: theme.palette.colors.white,
            },
          },
        }}
      />
    );
  });
};

export default SelectedMoves;
