import { addAlphaToHex } from "./helpers/colors";

const greyScale = {
  black900: "#03030D",
  black800: "#14142B",
  black700: "#1C1E37",
  black600: "#20223C",
  black500: "#262945",
  black400: "#323552",
  black300: "#424666",
  black200: "#585C80",
  black100: "#585C80",
  white: "#ffffff",
};

const mainColors = {
  green: "#9FFF7B",
  lightBlue: "#71E1FA",
  blue: "#30AAEA",
  darkBlue: "#15172E",
};

const colors = {
  ...greyScale,
  ...mainColors,
  primary: mainColors.lightBlue,
  secondary: mainColors.green,
  scrollbar: {
    background: greyScale.black800,
    thumb: greyScale.black400,
    thumbHover: mainColors.green,
  },
  typography: {
    main: greyScale.white,
  },
  game: {
    air: "#CFD3FF",
    fire: "#C33945",
    water: "#3981C3",
    earth: "#9FC339",
  },
};

const theme = {
  typography: {
    fontFamily: ["Oxanium", "sans-serif"].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "& ::selection": {
            background: colors.secondary,
            color: colors.black900,
            textShadow: "none",
          },
          "&::-moz-selection, & *::-moz-selection": {
            background: colors.secondary,
            color: colors.black900,
            textShadow: "none",
          },
          scrollbarColor: `${colors.scrollbar.thumb} ${colors.scrollbar.background}`,
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: colors.scrollbar.background,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: colors.scrollbar.thumb,
            minHeight: 24,
            border: `3px solid ${colors.scrollbar.background}`,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: colors.scrollbar.thumb,
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: colors.scrollbar.thumb,
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: colors.scrollbar.thumbHover,
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: colors.scrollbar.background,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: "3.25rem",
          lineHeight: 1.2,
          color: colors.typography.main,
          textTransform: "uppercase",
        },
        h2: {
          fontSize: "2.75rem",
          lineHeight: 1.25,
          color: colors.typography.main,
        },
        h3: {
          fontSize: "1.75rem",
          lineHeight: 1.3,
          color: colors.typography.main,
        },
        h4: {
          fontSize: "1.438rem",
          lineHeight: 1.3,
          color: colors.typography.main,
        },
        h5: {
          fontWeight: 700,
          fontSize: "1.188rem",
          lineHeight: 1.3,
          color: colors.typography.main,
        },
        body1: {
          lineHeight: 1.6,
          fontSize: "1.3rem",
          fontWeight: 400,
          color: colors.typography.main,
        },
        body2: {
          lineHeight: 1.6,
          fontSize: "1.2rem",
          fontWeight: 400,
          color: colors.typography.main,
        },
        span: {
          color: colors.typography.main,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "transparent",
          boxShadow: "none",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: colors.primary,
          color: colors.black900,
          fontSize: 12,
          fontWeight: 700,
          boxShadow: `0 0 5px ${addAlphaToHex(colors.darkBlue, 0.8)}`,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {},
        paper: {
          background: colors.black600,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${addAlphaToHex(colors.white, 0.1)}`,
          borderBottom: `1px solid ${addAlphaToHex(colors.white, 0.1)}`,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "space-between",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {},
    },
    MuiFormLabel: {
      styleOverrides: {},
    },
    MuiPagination: {
      styleOverrides: {},
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: colors.primary,
            border: `1px solid ${addAlphaToHex(colors.primary, 0.5)}`,
            backgroundColor: addAlphaToHex(colors.primary, 0.12),
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          transition: "0.2s all ease",
          borderRadius: "2px",
          fontWeight: 500,
          color: colors.white,
          "& .MuiOutlinedInput-notchedOutline": {
            transition: "0.2s all ease",
            border: `1px solid ${addAlphaToHex(colors.white, 0.3)}`,
            borderRadius: "2px",
          },
          "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
            {},
          "&:hover, &:active, &.Mui-focused": {},
          "&:hover": {
            boxShadow: `0px 0px 8px ${addAlphaToHex(colors.white, 0.3)}`,
          },
          "&:active, &.Mui-focused": {},
          "& .MuiInputAdornment-root": {
            color: "#fff",
            "& .MuiTypography-root": {
              color: "#fff",
              fontSize: 13,
            },
          },
          "& .MuiIconButton-root, & .MuiSelect-icon": {
            color: addAlphaToHex(colors.white, 0.5),
            "&:hover": {
              color: colors.white,
            },
          },
        },
        input: {
          "&::placeholder": {
            color: addAlphaToHex(colors.white, 0.5),
            opacity: 1,
            transition: "0.2s all ease",
          },
          "&:focus": {
            borderRadius: "2px",
          },
          "&:hover": {
            "&::placeholder": {
              color: colors.white,
            },
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          border: "1px solid transparent",
          borderRadius: "8px",
          fontWeight: 500,
          background: addAlphaToHex(colors.black900, 0.9),
          "&:hover": {
            background: colors.black900,
          },
          "&:active, &.Mui-focused": {
            boxShadow: `0px 0px 8px ${addAlphaToHex(colors.white, 0.4)}`,
          },
          "&:hover:not(.Mui-disabled):before": {
            borderColor: "transparent",
          },
          "&::before": {
            borderColor: "transparent",
          },
          "&::after": {
            borderColor: "transparent",
          },
        },
        input: {
          borderColor: "transparent",
          "&:focus": {
            borderRadius: "8px",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: addAlphaToHex(colors.white, 0.6),
        },
        shrink: {
          color: colors.white,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          userSelect: "none",
        },
        label: {
          lineHeight: 1,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {},
    },
    MuiPaper: {
      styleOverrides: {},
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          background: colors.black800,
          "& .MuiMenuItem-root": {
            fontWeight: 600,
            color: colors.white,
            "&:hover, &.Mui-selected, &.Mui-selected:hover": {
              background: colors.primary,
              color: colors.black900,
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: colors.black600,
          padding: "16px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          background: colors.black800,
          "& .MuiAutocomplete-option": {
            fontWeight: 600,
            color: colors.white,
            "&:hover": {
              background: addAlphaToHex(colors.primary, 0.1),
              backgroundColor: addAlphaToHex(colors.primary, 0.1),
              color: colors.white,
            },
          },
          ".MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused, .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true'], .MuiAutocomplete-listbox .MuiAutocomplete-option:hover, .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true']:hover, .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true'].Mui-focused":
            {
              background: addAlphaToHex(colors.primary, 0.1),
              backgroundColor: addAlphaToHex(colors.primary, 0.1),
              color: colors.white,
            },
        },
        option: {
          "&:hover, & .Mui-focused": {
            background: colors.primary,
            backgroundColor: colors.primary,
            color: colors.black900,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {},
    },
    MuiTabs: {
      styleOverrides: {},
    },
    MuiSnackbar: {
      styleOverrides: {},
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          background: colors.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {},
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: colors.white,
          textDecoration: "none",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1)",
          svg: {
            color: colors.white,
            height: "auto",
          },
          "&:hover": {
            backgroundColor: addAlphaToHex(colors.primary, 0.2),
            transform: "scale(1.15)",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: 200,
          "&:hover": {
            "& .MuiInputLabel-root": {
              color: colors.white,
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {},
    },
    MuiTablePagination: {
      styleOverrides: {},
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.white,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderColor: "transparent",
          fontWeight: "500",
          background: colors.black600,
          padding: 24,
          borderRadius: "2px",
        },
        checkboxInput: {
          color: colors.white,
        },
        cell: {
          borderColor: "transparent",
          color: colors.white,
          fontSize: 17,
          "&:focus-within": {
            outline: "none",
          },
        },
        columnHeader: {
          "&:focus-within": {
            outline: "none",
          },
        },
        columnHeaders: {
          borderColor: "transparent",
        },
        columnHeaderTitleContainer: {
          "> *": {
            fontSize: 19,
            fontWeight: 600,
            color: colors.white,
            userSelect: "none",
            "&:hover": {
              color: colors.white,
            },
          },
        },
        columnHeaderTitle: {
          fontWeight: 600,
        },
        columnSeparator: {
          color: "transparent",
        },
        menuIconButton: {
          color: colors.white,
        },
        row: {
          borderRadius: "2px",
          "&:nth-of-type(2n)": {
            background: colors.black500,
          },
          "&:hover, &.Mui-selected, &.Mui-selected:hover": {
            background: colors.black400,
          },
        },
        sortIcon: {
          display: "none",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${addAlphaToHex(colors.white, 0.1)}`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: "2px",
          background: colors.primary,
          border: "none",
          textTransform: "uppercase",
          fontWeight: 700,
          lineHeight: 1,
          transition: "0.2s transform ease",
          "& svg path": {
            fill: colors.black900,
          },
          "&:hover": {
            background: colors.primary,
            transform: "scale(1.1)",
            "& svg path": {
              fill: colors.black900,
            },
          },
        },
        containedPrimary: {},
        containedSecondary: {},
        containedSizeSmall: {},
        containedSizeMedium: {
          fontSize: "1rem",
          padding: "10px 20px",
          svg: {
            height: 16,
            width: "auto",
          },
        },
        containedSizeLarge: {},
        outlined: {
          borderRadius: "2px",
          textTransform: "none",
          border: `2px solid ${colors.primary}`,
          color: colors.white,
          fontWeight: 700,
          lineHeight: 1,
          "& svg path": {
            fill: colors.white,
          },
          "&:hover": {
            background: addAlphaToHex(colors.primary, 0.16),
            border: `2px solid ${colors.primary}`,
            color: colors.white,
            "& svg path": {
              fill: colors.white,
            },
          },
        },
        outlinedPrimary: {},
        outlinedSecondary: {},
        outlinedSizeSmall: {},
        outlinedSizeMedium: {
          fontSize: "1rem",
          padding: "8px 20px",
        },
        outlinedSizeLarge: {},
        text: {
          borderRadius: "2px",
          color: colors.white,
          background: addAlphaToHex(colors.black900, 0.8),
          textTransform: "uppercase",
          fontWeight: 700,
          letterSpacing: 0.5,
          "& .MuiButton-startIcon": {
            marginRight: "4px",
          },
          "&:hover": {
            background: addAlphaToHex(colors.primary, 0.2),
          },
        },
        textSizeSmall: {
          fontSize: "0.8rem",
          padding: "5px 12px 2px 12px",
          "& svg": {
            width: 16,
            height: "auto",
            position: "relative",
            top: -1,
          },
        },
        textSizeMedium: {
          fontSize: "1.1rem",
        },
        textSizeLarge: { fontSize: "1.3rem" },
        iconSizeSmall: {},
        iconSizeMedium: {},
        iconSizeLarge: {},
      },
    },
  },
  palette: {
    colors,
    white: {
      main: colors.white,
      contrastText: colors.black900,
    },
    black: {
      main: colors.black900,
      contrastText: colors.white,
    },
    primary: {
      main: colors.primary,
      contrastText: colors.black900,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.black900,
    },
    text: {
      primary: colors.primary,
      secondary: colors.secondary,
      bright: colors.white,
      dark: colors.black900,
      muted: addAlphaToHex(colors.white, 0.7),
    },
    background: {
      default: colors.darkBlue,
    },
  },
};

export default theme;
