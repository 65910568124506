export const MOVE_EXPLORER_UPDATE_FILTERS = "MOVE_EXPLORER_UPDATE_FILTERS";
export const MOVE_EXPLORER_UPDATE_SELECTED_MOVES =
  "MOVE_EXPLORER_UPDATE_SELECTED_MOVES";
export const MOVE_EXPLORER_CLEAR_FILTERS = "MOVE_EXPLORER_CLEAR_FILTERS";
export const MOVE_EXPLORER_UPDATE_SEARCH_DIALOG =
  "MOVE_EXPLORER_UPDATE_SEARCH_DIALOG";
export const MOVE_EXPLORER_FETCH_PIXL_PET_PRICES =
  "MOVE_EXPLORER_FETCH_PIXL_PET_PRICES";
export const MOVE_EXPLORER_FETCH_PIXL_PET_PRICES_STARTED =
  "MOVE_EXPLORER_FETCH_PIXL_PET_PRICES_STARTED";
