import { combineReducers } from "redux";
import moveExplorerReducer from "./moveExplorer/moveExplorerReducer";
import dataReducer from "./data/dataReducer";
import inspectorReducer from "./inspector/inspectorReducer";
import searchDialogReducer from "./searchDialog/searchDialogReducer";

const rootReducer = combineReducers({
  moveExplorer: moveExplorerReducer,
  data: dataReducer,
  inspector: inspectorReducer,
  searchDialog: searchDialogReducer,
});

export default rootReducer;
