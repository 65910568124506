import { Stack, Typography } from "@mui/material";
import React from "react";
import PageWrapper from "./Common/PageWrapper";
import SearchAgain from "./Common/SearchAgain";

const Error = () => {
  return (
    <PageWrapper>
      <Stack alignItems="center" alignSelf="center">
        <img src="/images/pixl-pets/air-pixl-pet.png" alt="Pixl Pet" />
        <Stack gap={2}>
          <Typography variant="body1" textAlign="center">
            Hmm, we can't seem to find that Pixl Pet.
          </Typography>
          <SearchAgain style={{ alignSelf: "center" }} />
        </Stack>
      </Stack>
    </PageWrapper>
  );
};

export default Error;
