import { Stack, Typography } from "@mui/material";
import React from "react";
import Trait from "./Trait";

const Header = ({ traitCount, rarity }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    gap={2}
  >
    <Typography variant="h3">Traits</Typography>
    <Trait
      value={`${traitCount} Total`}
      percentage={rarity?.traitCounts[traitCount]}
    />
  </Stack>
);

export default Header;
