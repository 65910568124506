import React from "react";
import TraitRow from "../Common/TraitRow";
import ValueAndPercentage from "../Common/ValueAndPercentage";
import Label from "../Common/Label";

const Trait = ({ label, value, percentage, width }) => {
  return (
    <TraitRow>
      <Label
        label={label}
        hasTrait={percentage != null}
        width={width}
        style={{ fontSize: (10 / 200) * width }}
      />

      <ValueAndPercentage
        label={label}
        value={value}
        percentage={percentage}
        style={{ gap: (0.5 / 200) * width }}
        valueStyle={{ fontSize: (10.5 / 200) * width }}
        percentageStyle={{
          fontSize: (9.5 / 200) * width,
          height: (15 / 200) * width,
          minWidth: (34 / 200) * width,
          "& .MuiChip-label": {
            paddingLeft: (0.1 / 200) * width,
            paddingRight: (0.1 / 200) * width,
            paddingTop: (0.4 / 200) * width,
            paddingBottom: (0.2 / 200) * width,
          },
        }}
      />
    </TraitRow>
  );
};

export default Trait;
