import { Stack } from "@mui/material";
import React from "react";
import Header from "./Header";
import SearchDialog from "../../components/SearchDialog/SearchDialog";
import Snackbar from "../../components/SearchDialog/Snackbar/Snackbar";
import { connect } from "react-redux";
import MovesTable from "../../components/PixlPet/Moves/MovesTable";
import { updateSelectedMoves } from "../../store/moveExplorer/moveExplorerActions";
import { updateSearchDialog } from "../../store/searchDialog/searchDialogActions";
import {
  getMovesToDisplay,
  getSelectedMoveData,
} from "../../store/moveExplorer/selectors/moveTableSelectors";
import { getSearchResults } from "../../store/moveExplorer/selectors/searchDialogSelectors";
import { getSearchDialog } from "../../store/moveExplorer/selectors/commonSelectors";

const MoveExplorer = ({
  moves,
  rarity,
  searchResults,
  updateSelectedMoves,
  selectedMovesRaw,
  selectedMoves,
  updateSearchDialog,
  searchDialog,
}) => {
  const handleUpdateSearchDialog = (...args) => {
    updateSearchDialog("moveExplorer", ...args);
  };

  return (
    <>
      <Stack gap={8}>
        <Header />
        <MovesTable
          moves={moves}
          selectedMoves={selectedMovesRaw}
          updateSelectedMoves={updateSelectedMoves}
        />
      </Stack>
      {!searchDialog.isOpen && (
        <Snackbar
          selectedMoves={selectedMoves}
          updateSelectedMoves={updateSelectedMoves}
          updateSearchDialog={handleUpdateSearchDialog}
        />
      )}
      {searchDialog.isOpen && (
        <SearchDialog
          searchDialog={searchDialog}
          results={searchResults}
          selectedMoves={selectedMoves}
          updateSelectedMoves={updateSelectedMoves}
          rarity={rarity}
          component="moveExplorer"
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  updateSelectedMoves,
  updateSearchDialog,
};

const mapStateToProps = (state) => ({
  moves: getMovesToDisplay(state),
  rarity: state.data.rarity,
  searchResults: getSearchResults(state),
  searchDialog: getSearchDialog(state),
  selectedMovesRaw: state.moveExplorer.selectedMoves,
  selectedMoves: getSelectedMoveData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveExplorer);
