import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import { addAlphaToHex } from "../../../helpers/colors";
import { labelStyles, labelFontStyles } from "./commonStyles";

const Element = ({ element }) => {
  const theme = useTheme();
  const color = theme.palette.colors.game[element.toLowerCase()];

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        ...labelStyles,
        background: color,
        border: `1px solid ${color}`,
        minWidth: 80,
        px: 0,
      }}
    >
      <Typography
        variant="span"
        color="text.bright"
        fontWeight={700}
        sx={{
          ...labelFontStyles,
          textShadow: `0 0 2px ${addAlphaToHex(
            theme.palette.colors.black900,
            0.6
          )}`,
        }}
      >
        {element}
      </Typography>
    </Stack>
  );
};

export default Element;
