import React from "react";
import CustomSwitch from "../CustomSwitch/CustomSwitch";
import Label from "../Typography/Label";
import { Stack } from "@mui/material";

const CustomToggle = ({ value, updateValue, labelTrue, labelFalse }) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Label style={{ position: "relative", top: 1 }}>
        {value ? labelTrue : labelFalse}
      </Label>
      <CustomSwitch checked={value} onChange={updateValue} />
    </Stack>
  );
};

export default CustomToggle;
