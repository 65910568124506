import {
  getPaginationEndIndex,
  getPaginationStartIndex,
  getTotalNumberOfPages,
} from "../../helpers/display";
import { getMatchingPixlPets, augmentPet } from "../../helpers/pixlPets";
import { sortByKeyString, sortById } from "../../helpers/sort";

const NUMBER_OF_RESULTS_PER_PAGE = 50;

export const getSearchDialogData = (
  pixlPets,
  allMoves,
  rarity,
  selectedMoves,
  searchDialog
) => {
  const rawResults = getMatchingPixlPets(
    pixlPets,
    selectedMoves,
    searchDialog.operatorIsAnd
  );

  const results = rawResults
    .filter((pet) => pet.sellOrderDetails != null || !searchDialog.buyNow)
    .map((pet) => augmentPet(pet, allMoves, rarity))
    .sort((a, b) => sortByKeyString(a, b, searchDialog.sort));

  const numberOfResults = results.length;
  const resultsToDisplay = resultsForPage(searchDialog.page, results);

  return {
    numberOfResults,
    numberOfPages: getTotalNumberOfPages(
      numberOfResults,
      NUMBER_OF_RESULTS_PER_PAGE
    ),
    numberOfSelectedMoves: selectedMoves.length,
    page: searchDialog.page,
    resultsToDisplay,
    idsForPageStringified: stringifyIds(resultsToDisplay),
    allIdsStringified: stringifyIds(rawResults),
  };
};

export const resultsForPage = (
  page,
  allResults,
  numPerPage = NUMBER_OF_RESULTS_PER_PAGE
) => {
  const startIndex = getPaginationStartIndex(page, numPerPage);
  const endIndex = getPaginationEndIndex(startIndex, numPerPage);

  return allResults.slice(startIndex, endIndex);
};

export const stringifyIds = (results) => {
  if (results == null || results.length === 0) {
    return undefined;
  }
  return [...results]
    .sort((a, b) => sortById(a, b))
    .map((r) => r.id)
    .join("-");
};
