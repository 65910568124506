import React, { useState } from "react";
import {
  Box,
  Chip,
  Stack,
  Checkbox,
  Autocomplete,
  TextField,
} from "@mui/material";

const Dropdown = ({ label, value, updateValue, options }) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      multiple
      autoHighlight
      options={options}
      value={value}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.value}
      onChange={(e, newValue) => {
        updateValue(newValue);
        setInputValue("");
      }}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          variant="outlined"
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <Box component="li" {...props}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Checkbox checked={selected} />
            {option.value} {option.suffix && option.suffix}
          </Stack>
        </Box>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            color="white"
            label={option.value}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
};

export default Dropdown;
