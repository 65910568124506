import React from "react";
import { connect } from "react-redux";
import {
  getPixlPet,
  getSearchDialog,
  getSearchResults,
  getSelectedMoveData,
} from "../../store/inspector/inspectorSelectors";
import Adult from "./Adult/Adult";
import Egg from "./Egg/Egg";
import SearchAgain from "./Common/SearchAgain";
import PageWrapper from "./Common/PageWrapper";
import SearchDialog from "../../components/SearchDialog/SearchDialog";
import { updateSelectedMoves } from "../../store/inspector/inspectorActions";
import { updateSearchDialog } from "../../store/searchDialog/searchDialogActions";
import Snackbar from "../../components/SearchDialog/Snackbar/Snackbar";
import { useTheme } from "@mui/system";

const Result = ({
  pet,
  rarity,
  searchResults,
  searchDialog,
  selectedMoves,
  selectedMovesRaw,
  updateSelectedMoves,
  updateSearchDialog,
}) => {
  const theme = useTheme();
  let content;

  const handleUpdateSearchDialog = (...args) => {
    updateSearchDialog("inspector", ...args);
  };

  if (pet.isHatched) {
    content = (
      <Adult
        pet={pet}
        selectedMoves={selectedMovesRaw}
        updateSelectedMoves={updateSelectedMoves}
      />
    );
  } else content = <Egg pet={pet} />;

  return (
    <PageWrapper>
      <SearchAgain
        style={{
          position: "absolute",
          right: 0,
          [theme.breakpoints.down("md")]: {
            position: "static",
            ml: "auto",
          },
        }}
      />
      {content}
      {!searchDialog.isOpen && (
        <Snackbar
          selectedMoves={selectedMoves}
          updateSelectedMoves={updateSelectedMoves}
          updateSearchDialog={handleUpdateSearchDialog}
        />
      )}
      {searchDialog.isOpen && (
        <SearchDialog
          searchDialog={searchDialog}
          results={searchResults}
          selectedMoves={selectedMoves}
          updateSelectedMoves={updateSelectedMoves}
          rarity={rarity}
          component="inspector"
        />
      )}
    </PageWrapper>
  );
};

const mapDispatchToProps = {
  updateSelectedMoves,
  updateSearchDialog,
};

const mapStateToProps = (state) => ({
  pet: getPixlPet(state),
  rarity: state.data.rarity,
  searchResults: getSearchResults(state),
  searchDialog: getSearchDialog(state),
  selectedMoves: getSelectedMoveData(state),
  selectedMovesRaw: state.inspector.selectedMoves,
});

export default connect(mapStateToProps, mapDispatchToProps)(Result);
