import { makePixlPetUrl, roundToNumDecimals } from "./display";
import {
  BASE_TRAIT_TYPES,
  BONUS_TRAIT_TYPES,
  WEIRD_CHARACTER_MAPPING,
} from "../config";

export const getTrait = (pet, traitType) => {
  if (pet == null) {
    return undefined;
  }
  const a = pet.attributes.find((a) => a.traitType === traitType);
  return a?.value == null
    ? undefined
    : a.value in WEIRD_CHARACTER_MAPPING
    ? WEIRD_CHARACTER_MAPPING[a.value]
    : a.value;
};

export const isDeity = (pet) => {
  const deity = getTrait(pet, "Deity");
  return deity !== undefined;
};

export const countTraits = (pet) => pet.attributes.length;

export const getTraits = (pet, category) => {
  const traits = {};

  for (let trait of category) {
    traits[trait] = getTrait(pet, trait);
  }

  return traits;
};

export const isHatched = (pet) => {
  const egg = getTrait(pet, "Egg");
  return egg === "Hatched";
};

export const makeUrlFromMoveName = (name) =>
  `https://opensea.io/collection/pixl-pets-genesis?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Moves&search[stringTraits][0][values][0]=${encodeURI(
    name
  )}`;

export const augmentPet = (pet, allMoves, rarity) => {
  const baseTraits = getTraits(pet, BASE_TRAIT_TYPES);
  const bonusTraits = getTraits(pet, BONUS_TRAIT_TYPES);

  return {
    ...pet,
    isDeity: isDeity(pet),
    traitCount: countTraits(pet),
    baseTraits,
    bonusTraits,
    traits: { ...baseTraits, ...bonusTraits },
    moves: getMoveData(pet, allMoves, rarity),
    isHatched: isHatched(pet),
    opensea: makePixlPetUrl(pet.id),
    sellOrderDetails: formatPrice(pet.sellOrderDetails),
  };
};

export const formatPrice = (priceData) => {
  if (priceData == null) {
    return undefined;
  }

  return {
    ...priceData,
    amount: parseFloat(priceData.amount),
    usdAmount: roundToNumDecimals(parseFloat(priceData.usdAmount), 2),
  };
};

export const getMoveData = (pet, allMoves, rarity) => {
  const moves = [];

  pet.attributes.forEach((attribute) => {
    if (attribute.traitType === "Moves") {
      const moveData = allMoves.find((m) => m.name === attribute.value);
      let moveRarity;
      let moveElement;
      if (moveData != null) {
        moveRarity = moveData.rarity;
        moveElement = moveData.element;
      }
      moves.push({
        id: attribute.value,
        name:
          attribute.value in WEIRD_CHARACTER_MAPPING
            ? WEIRD_CHARACTER_MAPPING[attribute.value]
            : attribute.value,
        rarity: moveRarity,
        element: moveElement,
        rarityPercent: rarity?.Moves
          ? rarity.Moves[attribute.value]
          : undefined,
        opensea: makeUrlFromMoveName(attribute.value),
      });
    }
  });

  const sortedMoves = moves.sort((a, b) => {
    if (a.rarity === b.rarity) {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    }
    return b.rarity - a.rarity;
  });

  return sortedMoves;
};

export const calculateRarity = (pixlPets) => {
  const attributes = {};
  const traitCounts = {};

  for (const pet of pixlPets) {
    const totalTraits = pet.attributes.length;

    if (totalTraits in traitCounts) {
      traitCounts[totalTraits] += 1 * (1 / pixlPets.length);
    } else {
      traitCounts[totalTraits] = 1 * (1 / pixlPets.length);
    }

    pet.attributes.forEach((attribute) => {
      if (!(attribute.traitType in attributes)) {
        attributes[attribute.traitType] = {};
      }
    });
  }

  for (const pet of pixlPets) {
    for (const attribute of pet.attributes) {
      let increment = 1 * (1 / pixlPets.length);
      const cleanedValue =
        attribute.value in WEIRD_CHARACTER_MAPPING
          ? WEIRD_CHARACTER_MAPPING[attribute.value]
          : attribute.value;

      if (cleanedValue in attributes[attribute.traitType]) {
        attributes[attribute.traitType][cleanedValue] += increment;
      } else {
        attributes[attribute.traitType][cleanedValue] = increment;
      }
    }
  }

  return {
    ...attributes,
    traitCounts,
  };
};

export const getMatchingPixlPets = (
  pixlPets,
  selectedMoves,
  searchOperatorIsAnd
) => {
  if (selectedMoves.length === 0) {
    return [];
  }

  const matchesMove = (attributes) => (move) =>
    attributes.find((a) => a.traitType === "Moves" && a.value === move) !==
    undefined;

  if (searchOperatorIsAnd) {
    return pixlPets.filter((pet) => {
      const attributes = pet.attributes;
      if (selectedMoves.every(matchesMove(attributes))) {
        return true;
      }
      return false;
    });
  }
  return pixlPets.filter((pet) => {
    const attributes = pet.attributes;
    if (selectedMoves.some(matchesMove(attributes))) {
      return true;
    }
    return false;
  });
};

export const petIsValid = (pet) => {
  if (
    pet?.edition == null ||
    pet?.name == null ||
    pet?.image == null ||
    pet?.attributes == null
  ) {
    return false;
  }
  return true;
};
