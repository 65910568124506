import { Stack, Typography } from "@mui/material";
import React from "react";
import MovesTable from "./MovesTable";

const Moves = ({ pet, selectedMoves, updateSelectedMoves }) => {
  return (
    <Stack gap={2} width="100%">
      <Typography variant="h3">Moves</Typography>
      <MovesTable
        moves={pet.moves}
        selectedMoves={selectedMoves}
        updateSelectedMoves={updateSelectedMoves}
        columnMinWidths={{
          name: [150, 150],
          element: [120, 120],
          rarity: [110, 110],
          rarityPercent: [110, 110],
          opensea: [80, 80],
        }}
        sortModel={[]}
        condensed={true}
      />
    </Stack>
  );
};

export default Moves;
