import {
  makePixlPetEndpoint,
  makePixlPetPriceEndpoint,
  pixlPetsEndpoint,
} from "./endpoints";
import { OPENSEA_API_KEY } from "../config";

export const requestPixlPets = async () => {
  const response = await fetch(pixlPetsEndpoint, {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

export const requestPixlPetOfficial = async (id) => {
  const response = await fetch(makePixlPetEndpoint(id), {
    method: "GET",
  });
  const data = await response.json();
  return data;
};

export const requestAddPixlPetToDatabase = async (payload) => {
  try {
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(pixlPetsEndpoint, params);
    const data = await response.json();
    return data;
  } catch (e) {
    // console.log(e);
  }
};

export const requestPixlPetPrices = async (ids) => {
  const response = await fetch(makePixlPetPriceEndpoint(ids), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "X-API-KEY": OPENSEA_API_KEY,
    },
  });
  const data = await response.json();
  return data;
};
