import React from "react";
import { Stack } from "@mui/material";
import TraitCount from "./TraitCount";
import MoveRarity from "./MoveRarity";
import InspectButton from "./InspectButton";
import CustomLink from "../../CustomLink/CustomLink";

const Image = ({ pixlPet, width, hoverEffect }) => {
  const hoverStyles = hoverEffect
    ? {
        transform: "scale(1.04)",
        ".inspectButton": {
          opacity: 1,
        },
      }
    : {};
  return (
    <Stack
      sx={{
        position: "relative",
        transition: "0.2s transform ease",
        width,
        img: {
          width,
        },
        "&:hover": {
          ...hoverStyles,
        },
      }}
    >
      <CustomLink href={pixlPet.opensea}>
        <img src={pixlPet.image} alt={pixlPet.name} />
      </CustomLink>
      {pixlPet.moves.map((move, i) => (
        <MoveRarity
          key={`RARITY_${i}`}
          pixlPet={pixlPet}
          rarity={move.rarity}
          i={i}
          width={width}
        />
      ))}
      <TraitCount
        traitCount={pixlPet.traitCount}
        pixlPet={pixlPet}
        width={width}
      />
      {hoverEffect && <InspectButton pixlPet={pixlPet} width={width} />}
    </Stack>
  );
};

export default Image;
