import * as types from "./dataActionTypes";

// Fetch all pets
export const fetchPixlPets = () => ({
  type: types.DATA_FETCH_PIXL_PETS,
});

export const fetchPixlPetsStarted = () => ({
  type: types.DATA_FETCH_PIXL_PETS_STARTED,
});

export const fetchPixlPetsSucceeded = (pixlPets, rarity) => ({
  type: types.DATA_FETCH_PIXL_PETS_SUCCEEDED,
  pixlPets,
  rarity,
});

export const fetchPixlPetsFailed = () => ({
  type: types.DATA_FETCH_PIXL_PETS_FAILED,
});

// Fetch new pet
export const fetchPixlPet = (id) => ({
  type: types.DATA_FETCH_PIXL_PET,
  id,
});

export const fetchPixlPetSucceeded = (pixlPet) => ({
  type: types.DATA_FETCH_PIXL_PET_SUCCEEDED,
  pixlPet,
});

export const updateRarity = (rarity) => ({
  type: types.DATA_UPDATE_RARITY,
  rarity,
});
