import React from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/system";

const MoveRarity = ({ rarity, i, width, pixlPet }) => {
  const theme = useTheme();
  const baseSize = (20 / 200) * width;

  const getLeft = () => {
    if (pixlPet.isDeity) {
      if (i === 0 || i === 2) {
        return "2%";
      }
      return "88%";
    }
    return "2%";
  };

  const getTop = () => {
    if (pixlPet.isDeity) {
      if (pixlPet.id === 5641) {
        if (i < 2) {
          return "70%";
        }
        return `92%`;
      }

      if (i < 2) {
        return "67%";
      }
      return `91%`;
    }
    return `calc(53.5% + ${i * baseSize + i * (18 / 200) * width}px)`;
  };

  return (
    <Tooltip title={`Tier ${rarity} rarity`} placement="left">
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          left: getLeft(),
          top: getTop(),
          background: theme.palette.colors.black900,
          width: `${baseSize}px`,
          height: `${baseSize}px`,
          borderRadius: "50%",
          border: `${(1 / 200) * width}px solid #fff`,
        }}
      >
        <Typography
          variant="span"
          fontSize={(12 / 200) * width}
          fontWeight={800}
        >
          {rarity}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default MoveRarity;
