import * as types from "./inspectorActionTypes";

// Inspector clear
export const inspectorClear = () => ({
  type: types.INSPECTOR_CLEAR,
});

// Inspector search
export const inspectorSearch = (id) => ({
  type: types.INSPECTOR_SEARCH,
  id,
});

export const inspectorSearchStarted = () => ({
  type: types.INSPECTOR_SEARCH_STARTED,
});

export const inspectorSearchSucceeded = (pixlPet) => ({
  type: types.INSPECTOR_SEARCH_SUCCEEDED,
  pixlPet,
});

export const inspectorSearchFailed = () => ({
  type: types.INSPECTOR_SEARCH_FAILED,
});

// Pet moves table
export const updateSelectedMoves = (selectedMoves) => ({
  type: types.INSPECTOR_UPDATE_SELECTED_MOVES,
  selectedMoves,
});

// Search dialog
export const updateSearchDialog = (key, value) => ({
  type: types.INSPECTOR_UPDATE_SEARCH_DIALOG,
  key,
  value,
});
