import { Stack, Typography } from "@mui/material";
import React from "react";
import Controls from "./Controls/Controls";
import PageTitle from "components/Typography/PageTitle";

const Header = () => {
  return (
    <Stack gap={2}>
      <Stack>
        <PageTitle>Move Explorer</PageTitle>
        <Typography variant="body1">
          Explore all genesis Pixl Pet moves, including their elements and
          rarity tiers.
        </Typography>
      </Stack>

      <Controls />
    </Stack>
  );
};

export default Header;
