export const SEARCH_DIALOG_UPDATE = "SEARCH_DIALOG_UPDATE";
export const SEARCH_DIALOG_FETCH_PIXL_PET_PRICES =
  "SEARCH_DIALOG_FETCH_PIXL_PET_PRICES";
export const SEARCH_DIALOG_FETCH_PIXL_PET_PRICES_STARTED =
  "SEARCH_DIALOG_FETCH_PIXL_PET_PRICES_STARTED";
export const SEARCH_DIALOG_FETCH_PIXL_PET_PRICES_SUCCEEDED =
  "SEARCH_DIALOG_FETCH_PIXL_PET_PRICES_SUCCEEDED";
export const SEARCH_DIALOG_FETCH_PIXL_PET_PRICES_FAILED =
  "SEARCH_DIALOG_FETCH_PIXL_PET_PRICES_FAILED";

export const SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES =
  "SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES";
export const SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES_STARTED =
  "SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES_STARTED";
export const SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES_SUCCEEDED =
  "SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES_SUCCEEDED";
export const SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES_FAILED =
  "SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES_FAILED";
