import React from "react";
import { IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/system";

const InspectButton = ({ pixlPet, width }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const size = mobile ? 24 : 20;
  const getTop = () => {
    if (pixlPet.isDeity) {
      if (pixlPet.id === 5641) {
        return "0.5%";
      }
      return "3%";
    }
    return "3%";
  };
  const getLeft = () => {
    if (pixlPet.isDeity) {
      if (pixlPet.id === 5641) {
        return "0%";
      }
      return "3.5%";
    }
    return "5%";
  };

  return (
    <Tooltip title="Inspect Pixl Pet">
      <IconButton
        to={`/inspector/${pixlPet.id}`}
        target="_blank"
        component={RouterLink}
        size="small"
        className="inspectButton"
        sx={{
          background: theme.palette.colors.darkBlue,
          position: "absolute",
          left: getLeft(),
          top: getTop(),
          opacity: mobile ? 0.5 : 0,
          width: `${(size / 200) * width}px`,
          height: `${(size / 200) * width}px`,
          "&:hover": {
            background: theme.palette.colors.darkBlue,
            transform: "none",
          },
        }}
      >
        <FaSearch />
      </IconButton>
    </Tooltip>
  );
};

export default InspectButton;
