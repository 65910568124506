import React, { useState } from "react";
import { Autocomplete, Chip, Checkbox, TextField, Stack } from "@mui/material";
import { connect } from "react-redux";
import { getMoves } from "../../../store/data/selectors/commonSelectors";
import { sortByKeyString } from "../../../helpers/sort";
import Element from "../../PixlPet/Labels/Element";
import Rarity from "../../PixlPet/Labels/Rarity";

const SelectedMoveDropdown = ({
  moves,
  selectedMoves,
  updateSelectedMoves,
}) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      fullWidth
      size="small"
      multiple
      disableCloseOnSelect
      options={moves}
      getOptionLabel={(option) => option.name}
      value={selectedMoves}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      onChange={(e, newValue) => {
        const moves = newValue.map((m) => m.name);
        updateSelectedMoves(moves);
        setInputValue("");
      }}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            alignItems={{ xs: "flex-start", sm: "flex-start", md: "center" }}
            gap={{ xs: 0.5, sm: 0.5, md: 1 }}
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <Checkbox checked={selected} />
              {option.name}
            </Stack>

            <Stack direction="row" alignItems="center" gap={1}>
              <Element element={option.element} />
              <Rarity rarity={option.rarity} />
            </Stack>
          </Stack>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Moves"
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
          }}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip color="white" label={option.name} {...getTagProps({ index })} />
        ))
      }
    />
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  moves:
    [...getMoves(state)].sort((a, b) => sortByKeyString(a, b, "name-asc")) ||
    [],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedMoveDropdown);
