import React from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { IoCloseCircle } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";

const Search = ({ label, value, updateValue }) => {
  return (
    <TextField
      value={value}
      placeholder={label}
      variant="outlined"
      autoComplete="off"
      spellCheck={false}
      onChange={(e) => updateValue(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FaSearch />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            size="small"
            position="end"
            onClick={() => updateValue("")}
            sx={{ color: "#fff" }}
          >
            <IoCloseCircle />
          </IconButton>
        ),
      }}
    />
  );
};

export default Search;
