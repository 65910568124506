export const INSPECTOR_CLEAR = "INSPECTOR_CLEAR";

export const INSPECTOR_SEARCH = "INSPECTOR_SEARCH";
export const INSPECTOR_SEARCH_STARTED = "INSPECTOR_SEARCH_STARTED";
export const INSPECTOR_SEARCH_SUCCEEDED = "INSPECTOR_SEARCH_SUCCEEDED";
export const INSPECTOR_SEARCH_FAILED = "INSPECTOR_SEARCH_FAILED";

export const INSPECTOR_UPDATE_SELECTED_MOVES =
  "INSPECTOR_UPDATE_SELECTED_MOVES";

export const INSPECTOR_UPDATE_SEARCH_DIALOG = "INSPECTOR_UPDATE_SEARCH_DIALOG";
