import React from "react";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { addAlphaToHex } from "../../../../helpers/colors";

const Label = ({ label, hasTrait, style }) => {
  const theme = useTheme();

  return (
    <Typography
      textTransform="uppercase"
      fontSize={13}
      lineHeight={1}
      fontWeight={600}
      color={
        hasTrait
          ? theme.palette.colors.primary
          : addAlphaToHex(theme.palette.colors.primary, 0.4)
      }
      sx={{ ...style }}
    >
      {label}
    </Typography>
  );
};

export default Label;
