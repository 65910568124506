import React, { useCallback, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import Results from "./Results";
import Pagination from "./Pagination";
import Header from "./Header";
import { updateSearchDialog } from "../../store/searchDialog/searchDialogActions";
import { connect } from "react-redux";
import SelectedMoveDropdown from "./SelectedMoves/Dropdown";
import CustomToggle from "../CustomToggle/CustomToggle";
import { fetchPixlPets } from "../../store/data/dataActions";

export const SEARCH_DIALOG_IS_OPEN = "isOpen";
export const SEARCH_DIALOG_PAGE = "page";
export const SEARCH_DIALOG_OPERATOR_IS_AND = "operatorIsAnd";
export const SEARCH_DIALOG_SHOW_TRAITS = "showTraits";
export const SEARCH_DIALOG_SORT = "sort";
export const SEARCH_DIALOG_MIN_PRICE = "minPrice";
export const SEARCH_DIALOG_MAX_PRICE = "maxPrice";
export const SEARCH_DIALOG_BUY_NOW = "buyNow";

export const initialSearchDialogState = {
  [SEARCH_DIALOG_IS_OPEN]: false,
  [SEARCH_DIALOG_PAGE]: 1,
  [SEARCH_DIALOG_OPERATOR_IS_AND]: true,
  [SEARCH_DIALOG_SHOW_TRAITS]: true,
  [SEARCH_DIALOG_SORT]: "id-asc",
  [SEARCH_DIALOG_MIN_PRICE]: 0,
  [SEARCH_DIALOG_MAX_PRICE]: 0,
  [SEARCH_DIALOG_BUY_NOW]: false,
};

export const operationsWithPageReset = [
  SEARCH_DIALOG_BUY_NOW,
  SEARCH_DIALOG_OPERATOR_IS_AND,
];

const SearchDialog = ({
  results,
  searchDialog,
  selectedMoves,
  updateSelectedMoves,
  rarity,
  updateSearchDialog,
  component,
  fetchPixlPets,
}) => {
  const handleUpdateSearchDialog = useCallback(
    (...args) => {
      updateSearchDialog(component, ...args);
    },
    [updateSearchDialog, component]
  );

  const handleClose = useCallback(() => {
    handleUpdateSearchDialog(SEARCH_DIALOG_IS_OPEN, false);
    handleUpdateSearchDialog(SEARCH_DIALOG_PAGE, 1);
  }, [handleUpdateSearchDialog]);

  const handleUpdateSearchOperator = (e) => {
    handleUpdateSearchDialog(SEARCH_DIALOG_OPERATOR_IS_AND, e.target.checked);
  };

  const handleUpdatePage = (page) => {
    fetchPixlPets();
    handleUpdateSearchDialog(SEARCH_DIALOG_PAGE, page);
  };

  useEffect(() => {
    if (results.numberOfSelectedMoves === 0) {
      handleClose();
    }
  }, [results, handleClose]);

  return (
    <Dialog onClose={handleClose} open={searchDialog.isOpen} fullScreen>
      <DialogTitle>
        <Header
          results={results}
          searchDialog={searchDialog}
          updateSearchDialog={handleUpdateSearchDialog}
          component={component}
        />
      </DialogTitle>
      <DialogContent dividers>
        <Stack gap={2} sx={{ height: "100%" }}>
          <Stack
            gap={{ xs: 2, sm: 2, md: 4 }}
            direction="row"
            flexWrap={{ xs: "wrap", sm: "wrap", md: "nowrap" }}
          >
            <SelectedMoveDropdown
              selectedMoves={selectedMoves}
              updateSelectedMoves={updateSelectedMoves}
            />
            <CustomToggle
              value={searchDialog.operatorIsAnd}
              updateValue={handleUpdateSearchOperator}
              labelFalse="Moves: Any"
              labelTrue="Moves: All"
            />
          </Stack>
          <Results
            resultsToDisplay={results.resultsToDisplay}
            showTraits={searchDialog.showTraits}
            rarity={rarity}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Pagination results={results} updatePage={handleUpdatePage} />
        <Button
          variant="contained"
          disableElevation
          onClick={handleClose}
          autoFocus
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  updateSearchDialog,
  fetchPixlPets,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchDialog);
