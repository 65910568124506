import { Stack } from "@mui/material";
import React from "react";
import Labels from "../Common/Labels";
import Name from "../Common/Name";
import OpenSeaButton from "../Common/OpenSeaButton";
import Price from "../Common/Price";

const Egg = ({ pet }) => {
  const element = pet.traits.Element;

  return (
    <Stack
      sx={{ ".eggImage": { width: "100%", maxWidth: 400 } }}
      width="100%"
      gap={6}
    >
      <Stack gap={1}>
        <Stack direction="row" gap={2} alignItems="center">
          <Name name={pet.name} />
          <OpenSeaButton url={pet.opensea} />
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          <Labels element={element} unhatched={true} />
          <Price pet={pet} />
        </Stack>
      </Stack>
      <img
        src={`/images/eggs/${element}.gif`}
        alt={`${element} Pixl Pet Egg`}
        className="eggImage"
      />
    </Stack>
  );
};

export default Egg;
