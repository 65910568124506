import * as types from "./inspectorActionTypes";
import produce from "immer";

const initialState = {
  loading: false,
  failed: false,
  pixlPet: undefined,
  selectedMoves: [],
};

export const inspectorReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // Clear
      case types.INSPECTOR_CLEAR:
        draft.pixlPet = undefined;
        draft.selectedMoves = [];
        break;

      // Search
      case types.INSPECTOR_SEARCH_STARTED:
        draft.loading = true;
        draft.failed = false;
        break;
      case types.INSPECTOR_SEARCH_SUCCEEDED:
        draft.loading = false;
        draft.pixlPet = action.pixlPet;
        break;
      case types.INSPECTOR_SEARCH_FAILED:
        draft.loading = false;
        draft.failed = true;
        break;

      // Update Selected Moves
      case types.INSPECTOR_UPDATE_SELECTED_MOVES:
        draft.selectedMoves = action.selectedMoves;
        break;

      default:
        break;
    }
  });

export default inspectorReducer;
