import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/styles.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { StyledEngineProvider } from "@mui/material/styles";
import CustomRoutes from "./CustomRoutes";
import CssBaseline from "@mui/material/CssBaseline";
import { connect } from "react-redux";
import { fetchPixlPets } from "./store/data/dataActions";

function App({ fetchPixlPets }) {
  const customTheme = createTheme(theme);

  useEffect(() => {
    fetchPixlPets();
  });

  return (
    <ThemeProvider theme={customTheme}>
      <StyledEngineProvider injectFirst>
        <Router>
          <CssBaseline />
          <CustomRoutes />
        </Router>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

const mapDispatchToProps = {
  fetchPixlPets,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
