import { Stack, Typography } from "@mui/material";
import React from "react";
import { labelStyles, labelFontStyles } from "./commonStyles";

const RarityCell = ({ rarity }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ background: "#fff", minWidth: 60, ...labelStyles }}
    >
      <Typography
        variant="span"
        color="text.dark"
        fontWeight={700}
        sx={labelFontStyles}
      >
        {rarity}
      </Typography>
    </Stack>
  );
};

export default RarityCell;
