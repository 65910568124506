export const getFilters = (state) => {
  return state.moveExplorer.filters || {};
};

export const getSelectedMoves = (state) => {
  return state.moveExplorer.selectedMoves || [];
};

export const getSearchDialog = (state) => {
  return state.searchDialog.moveExplorer;
};
