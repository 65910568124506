import React from "react";
import { CircularProgress, Stack } from "@mui/material";
import { useTheme } from "@mui/system";

const CustomLoader = ({ width = 70, color, py = 20, style }) => {
  const theme = useTheme();
  if (!color) {
    color = theme.palette.secondary.main;
  }
  return (
    <Stack alignItems="center" sx={{ py }}>
      <CircularProgress size={width} sx={{ color, ...style }} />
    </Stack>
  );
};

export default CustomLoader;
