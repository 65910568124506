import { createSelector } from "reselect";
import {
  getMoves,
  getPixlPets,
  getRarity,
} from "../../data/selectors/commonSelectors";
import { getSelectedMoves, getSearchDialog } from "./commonSelectors";
import { getSearchDialogData } from "../../searchDialog/search";

export const getSearchResults = createSelector(
  [getPixlPets, getMoves, getRarity, getSelectedMoves, getSearchDialog],
  (pixlPets, allMoves, rarity, selectedMoves, searchDialog) => {
    return getSearchDialogData(
      pixlPets,
      allMoves,
      rarity,
      selectedMoves,
      searchDialog
    );
  }
);
