import produce from "immer";
import { moves } from "./moves";
import * as types from "./dataActionTypes";
import { getCurrentTimestamp } from "../../helpers/time";

export const initialState = {
  moves,
  pixlPets: { data: [], fetched: 0 },
};

export const dataReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // Fetch All Pixl Pet Data
      case types.DATA_FETCH_PIXL_PETS_STARTED:
        draft.pixlPets.loading = true;
        draft.pixlPets.failed = false;
        break;
      case types.DATA_FETCH_PIXL_PETS_SUCCEEDED:
        draft.pixlPets.loading = false;
        draft.pixlPets.data = action.pixlPets;
        draft.pixlPets.fetched = getCurrentTimestamp();
        draft.rarity = action.rarity;
        break;
      case types.DATA_FETCH_PIXL_PETS_FAILED:
        draft.pixlPets.loading = false;
        draft.pixlPets.failed = true;
        break;

      // Fetch Single Pixl Pet
      case types.DATA_FETCH_PIXL_PET_SUCCEEDED: {
        const idx = draft.pixlPets.data.find((p) => p.id === action.pixlPet.id);
        if (idx == null) {
          draft.pixlPets.data.push(action.pixlPet);
        }
        break;
      }

      // Update Rarity after Fetching New Hatched Pet
      case types.DATA_UPDATE_RARITY:
        draft.rarity = action.rarity;
        break;

      default:
        break;
    }
  });

export default dataReducer;
