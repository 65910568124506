export const sortById = (a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0);

export const sortByKeyString = (a, b, keyString) => {
  const arr = keyString.split("-");

  let aVal;
  let bVal;

  if (arr[0] === "price") {
    aVal = a.sellOrderDetails?.amount || 0;
    bVal = b.sellOrderDetails?.amount || 0;
  } else {
    aVal = a[arr[0]] || 0;
    bVal = b[arr[0]] || 0;
  }

  if (arr[1] === "asc") {
    return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
  }
  return aVal < bVal ? 1 : aVal > bVal ? -1 : 0;
};
