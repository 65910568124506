import React, { useState, useEffect } from "react";
import {
  Container,
  Toolbar,
  IconButton,
  Drawer,
  AppBar,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import Logo from "./Logo";
import { CgMenuGridR } from "react-icons/cg";
import { useTheme } from "@mui/system";
import Socials from "./Socials";
import { useLocation, Link as RouterLink } from "react-router-dom";

const Navigation = () => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  const links = [
    { text: "Move Explorer", to: "/" },
    { text: "Inspector", to: "/inspector" },
  ];

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const getLinks = () => {
    const underline = mobileView
      ? {}
      : {
          "&::after": {
            position: "absolute",
            top: "110%",
            content: "''",
            width: "0%",
            height: "2px",
            backgroundColor: theme.palette.colors.secondary,
            transition: "0.4s width ease",
          },
        };
    const activeStyles = mobileView
      ? { background: "transparent", color: theme.palette.colors.secondary }
      : {
          background: "transparent",
          "&::after": {
            width: "calc(100% - 16px)",
          },
        };
    return (
      <List
        sx={{
          display: "flex",
          flexDirection: mobileView ? "column" : "row",
          gap: 3,
          "& .MuiListItem-root": {
            color: "#fff",
            position: "relative",
            textAlign: mobileView ? "left" : "center",
            px: 1,
            ...underline,
            "&.Mui-selected": {
              ...activeStyles,
              ".MuiListItemText-root": {
                color: mobileView ? theme.palette.colors.secondary : "inherit",
              },
              "&:hover": {
                background: "transparent",
              },
            },
            "&:hover": {
              ...activeStyles,
            },
            "& .MuiListItemText-root": {
              my: 0,
            },
          },
        }}
      >
        {links.map(({ text, to }) => {
          return (
            <ListItem
              button
              component={RouterLink}
              to={to}
              selected={
                to === pathname ||
                (to !== "/" &&
                  pathname.split("/").includes(to.replace("/", "")))
              }
              key={`NAV_ITEM_${to}`}
              disableGutters
              disableRipple
            >
              <ListItemText
                primary={text}
                primaryTypographyProps={{
                  fontWeight: 600,
                  variant: "body2",
                  fontSize: 17,
                  color: "inherit",
                  whiteSpace: "nowrap",
                }}
              />
            </ListItem>
          );
        })}
      </List>
    );
  };

  const displayDesktop = () => {
    return (
      <Toolbar disableGutters sx={{ justifyContent: "space-between", py: 1 }}>
        <Logo />
        <Stack direction="row" gap={3} alignItems="center">
          {getLinks()}
          <Socials />
        </Stack>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar disableGutters sx={{ justifyContent: "space-between", py: 1 }}>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          sx={{ "& .MuiPaper-root": { py: 6, px: 2 } }}
        >
          <Logo style={{ mb: 4 }} />
          {getLinks()}
        </Drawer>

        <Logo />

        <IconButton
          {...{
            edge: "start",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
          sx={{ color: "#fff" }}
        >
          <CgMenuGridR />
        </IconButton>
      </Toolbar>
    );
  };

  return (
    <Container sx={{ pt: 1 }}>
      <AppBar position="static">
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </Container>
  );
};
export default Navigation;
