export const pluralize = (text, num) => {
  if (num === 1) {
    return `${num} ${text}`;
  } else return `${num} ${text}s`;
};

export const makePixlPetUrl = (id) =>
  `https://opensea.io/assets/0x4e76c23fe2a4e37b5e07b5625e17098baab86c18/${id}`;

export const getPaginationStartIndex = (currentPage, numPerPage) =>
  (currentPage - 1) * numPerPage;

export const getPaginationEndIndex = (start, numPerPage) => start + numPerPage;

export const getTotalNumberOfPages = (numberOfResults, numberPerPage) =>
  numberOfResults ? Math.ceil(numberOfResults / numberPerPage) : 1;

export const displayPercent = (x) => {
  return (
    (x * 100).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }) + "%"
  );
};

export const formatEthPrice = (price) => {
  if (price * Math.pow(10, -18) > 10000) {
    return (
      Math.round((price * Math.pow(10, -18) + Number.EPSILON) * 10000) / 10000
    ).toPrecision(5);
  }
  return (
    Math.round((price * Math.pow(10, -18) + Number.EPSILON) * 10000) / 10000
  );
};

export const roundToNumDecimals = (num, n = 3) => {
  return Math.round((num + Number.EPSILON) * 10 ** n) / 10 ** n;
};

export const chunk = (array, size) =>
  array.reduce((acc, _, i) => {
    if (i % size === 0) acc.push(array.slice(i, i + size));
    return acc;
  }, []);
