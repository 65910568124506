export const hexToRgb = (hex) => {
  hex = hex.replace("#", "");
  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  return [r, g, b];
};

export const addAlphaToHex = (hex, alpha = 1) => {
  const rgba = [...hexToRgb(hex), alpha];
  return `rgba(${rgba.join(",")})`;
};
