import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Element from "../Labels/Element";
import Rarity from "../Labels/Rarity";
import { opensea } from "../../../icons";
import { IconButton, Tooltip } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { displayPercent } from "../../../helpers/display";

const MovesTable = ({
  moves,
  updateSelectedMoves,
  selectedMoves,
  columnMinWidths = {
    name: [180, 250],
    element: [180, 180],
    rarity: [160, 160],
    rarityPercent: [120, 140],
    opensea: [80, 100],
  },
  sortModel = [{ field: "name", sort: "asc" }],
  condensed = false,
}) => {
  const theme = useTheme();
  const prevSelectedMoves = React.useRef(selectedMoves);
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: smDown ? columnMinWidths.name[0] : columnMinWidths.name[1],
    },

    {
      field: "element",
      minWidth: smDown
        ? columnMinWidths.element[0]
        : columnMinWidths.element[1],
      headerName: "Element",
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues) => <Element element={cellValues.row.element} />,
    },
    {
      field: "rarity",
      minWidth: smDown ? columnMinWidths.rarity[0] : columnMinWidths.rarity[0],
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues) => <Rarity rarity={cellValues.row.rarity} />,
      renderHeader: () => (
        <>
          <Tooltip title="Move rarity tier (1 = lowest, 5 = highest).">
            <span>Tier</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "rarityPercent",
      minWidth: smDown
        ? columnMinWidths.rarityPercent[0]
        : columnMinWidths.rarityPercent[1],
      headerAlign: "center",
      align: "center",
      renderCell: (cellValues) => (
        <>
          {cellValues.row.rarityPercent == null
            ? ""
            : displayPercent(cellValues.row.rarityPercent)}
        </>
      ),
      renderHeader: () => (
        <>
          <Tooltip title="The % of hatched Pixl Pets with a given move.">
            <span>Rarity %</span>
          </Tooltip>
        </>
      ),
    },
    {
      field: "opensea",
      width: smDown ? columnMinWidths.opensea[0] : columnMinWidths.opensea[1],
      headerName: "",
      align: "center",
      renderCell: (cellValues) => (
        <IconButton
          href={cellValues.row.opensea}
          target="_blank"
          sx={{
            opacity: 0.5,
            svg: {
              width: 20,
              height: "auto",
            },
            "&:hover": {
              opacity: 1,
            },
          }}
        >
          {opensea()}
        </IconButton>
      ),
    },
    {
      field: "id",
      flex: 1,
      headerName: "",
      renderCell: () => <></>,
    },
  ];

  useEffect(() => {
    prevSelectedMoves.current = selectedMoves;
  }, [selectedMoves]);

  useEffect(() => {
    updateSelectedMoves(prevSelectedMoves.current);
  }, [moves, updateSelectedMoves]);

  return (
    <DataGrid
      autoHeight
      checkboxSelection
      disableSelectionOnClick
      rows={moves}
      columns={columns}
      rowHeight={42}
      hideFooter={true}
      disableColumnMenu={true}
      initialState={{
        sorting: {
          sortModel,
        },
        pagination: {
          pageSize: 136,
        },
      }}
      onSelectionModelChange={(newSelectionModel) => {
        updateSelectedMoves(newSelectionModel);
      }}
      selectionModel={selectedMoves}
      sx={{
        p: condensed ? 1 : 3,
        "& .MuiDataGrid-row:hover .MuiDataGrid-cell:first-of-type": {
          fontWeight: 700,
        },
        "& .MuiCheckbox-root": {
          opacity: 0.2,
          "&:hover, &.Mui-checked": {
            opacity: 1,
          },
        },
        "& .MuiDataGrid-row:hover .MuiCheckbox-root": {
          opacity: 1,
        },
        "& .MuiDataGrid-iconButtonContainer": {
          display: "none",
        },
      }}
    />
  );
};

export default MovesTable;
