import React from "react";
import SearchBox from "./SearchBox";
import PageHeader from "../../../components/Layout/PageHeader";
import { Stack } from "@mui/material";

const Search = () => {
  return (
    <Stack gap={4}>
      <PageHeader
        title="Pixl Pet Inspector"
        description="Enter a Pixl Pet's ID to view its detailed profile, including moves
          and trait rarity."
      />
      <SearchBox />
    </Stack>
  );
};

export default Search;
