import * as types from "./moveExplorerActionTypes";

export const updateFilters = (filters) => ({
  type: types.MOVE_EXPLORER_UPDATE_FILTERS,
  filters,
});

export const clearFilters = () => ({
  type: types.MOVE_EXPLORER_CLEAR_FILTERS,
});

export const updateSelectedMoves = (selectedMoves) => ({
  type: types.MOVE_EXPLORER_UPDATE_SELECTED_MOVES,
  selectedMoves,
});
