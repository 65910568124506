import React from "react";
import { Pagination as MuiPagination } from "@mui/material";

const Pagination = ({ results, updatePage }) => {
  const handlePageChange = (e, value) => {
    updatePage(value);
  };

  return (
    <MuiPagination
      variant="outlined"
      color="primary"
      count={results.numberOfPages}
      page={results.page}
      onChange={handlePageChange}
    />
  );
};

export default Pagination;
