import { Link } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const CustomLink = ({
  children,
  to,
  hashTo,
  href,
  startSpace = true,
  endSpace = true,
  style,
}) => {
  return (
    <>
      {startSpace && " "}
      <LinkWrapped hashTo={hashTo} to={to} href={href} style={style}>
        {children}
      </LinkWrapped>
      {endSpace && " "}
    </>
  );
};

const LinkWrapped = ({ to, hashTo, href, children, style }) =>
  to != null ? (
    <Link to={to} component={RouterLink} sx={style}>
      {children}
    </Link>
  ) : hashTo != null ? (
    <Link to={hashTo} component={HashLink} sx={style}>
      {children}
    </Link>
  ) : (
    <Link href={href} target="_blank" sx={style}>
      {children}
    </Link>
  );

export default CustomLink;
