import { Stack } from "@mui/material";
import React from "react";
import PageDescription from "../Typography/PageDescription";
import PageTitle from "../Typography/PageTitle";

const PageHeader = ({ title, description }) => {
  return (
    <Stack gap={1}>
      <PageTitle>{title}</PageTitle>
      <PageDescription>{description}</PageDescription>
    </Stack>
  );
};

export default PageHeader;
