import { Stack, Typography } from "@mui/material";
import React from "react";
import PixlPet from "../../components/PixlPet/PixlPet";

const Results = ({ resultsToDisplay, rarity, showTraits }) => {
  return resultsToDisplay.length === 0 ? (
    <NoResults />
  ) : (
    <ListOfResults
      results={resultsToDisplay}
      rarity={rarity}
      showTraits={showTraits}
    />
  );
};

const NoResults = () => (
  <Stack alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
    <Typography
      variant="span"
      color="text.muted"
      fontWeight={700}
      fontSize={20}
    >
      No results
    </Typography>
  </Stack>
);

const ListOfResults = ({ results, rarity, showTraits }) => (
  <Stack
    direction="row"
    flexWrap="wrap"
    gap={2}
    justifyContent="center"
    sx={{ pb: 2 }}
  >
    {results.map((result, i) => (
      <PixlPet
        pixlPet={result}
        key={`RESULT_${i}`}
        rarity={rarity}
        showTraits={showTraits}
        minHeight={401.68}
      />
    ))}
  </Stack>
);

export default Results;
