import React from "react";
import { Button } from "@mui/material";
import { HiArrowRight } from "react-icons/hi";
import { Link as RouterLink } from "react-router-dom";

const SearchAgain = ({ style }) => {
  return (
    <Button
      component={RouterLink}
      to="/inspector"
      endIcon={<HiArrowRight />}
      variant="text"
      size="small"
      sx={style}
    >
      Search Again
    </Button>
  );
};

export default SearchAgain;
