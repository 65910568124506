import React from "react";
import Label from "../Common/Label";
import TraitRow from "../Common/TraitRow";
import ValueAndPercentage from "../Common/ValueAndPercentage";

const Trait = ({ label, value, percentage }) => {
  return (
    <TraitRow style={{ px: 1.25, minHeight: 20 }}>
      <Label label={label} hasTrait={percentage != null} />
      <ValueAndPercentage label={label} value={value} percentage={percentage} />
    </TraitRow>
  );
};

export default Trait;
