import React from "react";
import { Routes, Route } from "react-router";
import MoveExplorer from "pages/MoveExplorer/MoveExplorer";
import Inspector from "pages/Inspector/Inspector";
import Navigation from "components/Navigation/Navigation";
import Footer from "components/Footer/Footer";
import ScrollToTop from "components/Utility/ScrollToTop";
import { Box } from "@mui/material";
import Page from "components/Layout/Page";
import TermsAndConditions from "pages/About/TermsAndConditions";
import PrivacyPolicy from "pages/About/PrivacyPolicy";

const SitePage = ({ children, minHeight }) => (
  <Box sx={{ backgroundImage: "url('/images/stars.jpg')" }}>
    <ScrollToTop />
    <Navigation />
    <Page minHeight={minHeight}>{children}</Page>
    <Footer />
  </Box>
);

const CustomRoutes = () => (
  <Routes>
    <Route
      exact
      path="/"
      element={
        <SitePage>
          <MoveExplorer />
        </SitePage>
      }
    />
    <Route
      exact
      path="/move-explorer"
      element={
        <SitePage>
          <MoveExplorer />
        </SitePage>
      }
    />
    <Route
      exact
      path="/inspector/:id"
      element={
        <SitePage>
          <Inspector />
        </SitePage>
      }
    />
    <Route
      exact
      path="/inspector"
      element={
        <SitePage>
          <Inspector />
        </SitePage>
      }
    />
    <Route
      exact
      path="/terms-and-conditions"
      element={
        <SitePage>
          <TermsAndConditions />
        </SitePage>
      }
    />
    <Route
      exact
      path="/privacy-policy"
      element={
        <SitePage>
          <PrivacyPolicy />
        </SitePage>
      }
    />
  </Routes>
);

export default CustomRoutes;
