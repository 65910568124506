import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import Trait from "./Trait";

const TraitColumns = ({ pet, rarity }) => {
  if (pet.traits == null) {
    return null;
  }

  return (
    <Grid container spacing={4}>
      <TraitColumn
        title="Base Traits"
        traits={pet.baseTraits}
        moves={pet.moves}
        rarity={rarity}
      />
      <TraitColumn
        title="Bonus Traits"
        traits={pet.bonusTraits}
        rarity={rarity}
      />
    </Grid>
  );
};

const TraitColumn = ({ title, traits, rarity, moves = [] }) => {
  const theme = useTheme();
  const count =
    Object.keys(traits).filter((k) => traits[k] != null).length + moves.length;
  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Stack sx={{ background: theme.palette.colors.black800 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ py: 0.75, px: 1.25, background: theme.palette.colors.black600 }}
        >
          <Typography
            variant="span"
            textTransform="uppercase"
            fontWeight={700}
            fontSize={15}
          >
            {title}
          </Typography>
          <Typography variant="span" fontWeight={700} fontSize={17}>
            {count}
          </Typography>
        </Stack>
        <Stack gap={1} sx={{ py: 1 }}>
          {Object.keys(traits).map((trait, i) => (
            <Trait
              key={`TRAIT_${i}`}
              value={traits[trait]}
              percentage={rarity ? rarity[trait][traits[trait]] : undefined}
              label={trait}
            />
          ))}
          {moves.map((move, i) => (
            <Trait
              key={`TRAIT_${i}`}
              value={move.name}
              percentage={move.rarityPercent}
              label="Move"
            />
          ))}
        </Stack>
      </Stack>
    </Grid>
  );
};

export default TraitColumns;
