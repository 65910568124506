import React from "react";
import { Stack } from "@mui/material";
import Controls from "./Controls";
import Title from "./Title";

const Header = ({ results, searchDialog, updateSearchDialog, component }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      gap={2}
      alignItems="center"
      flexWrap="wrap"
    >
      <Title results={results} />
      <Controls
        searchDialog={searchDialog}
        updateSearchDialog={updateSearchDialog}
        component={component}
        results={results}
      />
    </Stack>
  );
};

export default Header;
