import React from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { pluralize } from "../../helpers/display";
import { MdInfo } from "react-icons/md";

const Title = ({ results }) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {pluralize("Search Result", results.numberOfResults)}
      <Tooltip
        title="Rarity % is calculated using trait counts from hatched Pixl Pets. Eggs are excluded."
        placement="right"
      >
        <IconButton size="small">
          <MdInfo />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default Title;
