import { Divider, Stack } from "@mui/material";
import React from "react";
import Trait from "./Trait";

const Traits = ({ width, traitCount, traits, rarity, showTraits }) => {
  if (rarity == null) {
    return null;
  }
  const dividerMargin = (4 / 200) * width;
  const dividerWidth = 1;
  const traitSpacing = (4 / 200) * width;
  const traitHeight = (15 / 200) * width;

  const traitsToDisplay = [
    "Element",
    "Card",
    "Body",
    "Eyes",
    "Mouth",
    "Egg",
    "Accessories",
    "Backs",
    "Face Details",
    "Glasses",
    "Hats",
    "Tails",
    "Wings",
    "Deity",
  ];

  const traitStyles = {
    height: showTraits
      ? `${
          (traitsToDisplay.length + 1) * (traitHeight + traitSpacing) +
          dividerMargin * 2 +
          dividerWidth
        }px`
      : 0,
    width,
    overflow: "hidden",
    transition: "0.45s all ease",
    "& .trait": {
      height: `${traitHeight}px`,
      minHeight: `${traitHeight}px`,
    },
  };

  return (
    <Stack gap={`${traitSpacing}px`} sx={traitStyles}>
      <Divider sx={{ my: `${dividerMargin}px` }} />
      <Trait
        label={`${traitCount} Total Traits`}
        percentage={rarity.traitCounts[traitCount]}
        width={width}
      />

      {traitsToDisplay.map((traitType, i) => (
        <Trait
          key={`TRAIT_${i}`}
          label={traitType}
          value={traits[traitType]}
          percentage={rarity[traitType][traits[traitType]]}
          width={width}
        />
      ))}
    </Stack>
  );
};

export default Traits;
