export const moves = [
  { id: "Earthquake", name: "Earthquake", rarity: 5, element: "Earth" },
  { id: "Leverage", name: "Leverage", rarity: 5, element: "Earth" },
  { id: "Gaia Spirit", name: "Gaia Spirit", rarity: 5, element: "Earth" },
  { id: "Overgrowth", name: "Overgrowth", rarity: 5, element: "Earth" },

  { id: "Fissure", name: "Fissure", rarity: 4, element: "Earth" },
  { id: "Pillar", name: "Pillar", rarity: 4, element: "Earth" },
  { id: "Fortress", name: "Fortress", rarity: 4, element: "Earth" },
  { id: "Stable Ground", name: "Stable Ground", rarity: 4, element: "Earth" },
  { id: "Titan Rush", name: "Titan Rush", rarity: 4, element: "Earth" },
  { id: "Leaf Storm", name: "Leaf Storm", rarity: 4, element: "Earth" },

  { id: "Aftershock", name: "Aftershock", rarity: 3, element: "Earth" },
  { id: "Sturdy", name: "Sturdy", rarity: 3, element: "Earth" },
  { id: "Collapse", name: "Collapse", rarity: 3, element: "Earth" },
  { id: "Calcify", name: "Calcify", rarity: 3, element: "Earth" },
  { id: "Stone Breath", name: "Stone Breath", rarity: 3, element: "Earth" },
  { id: "Sacred Garden", name: "Sacred Garden", rarity: 3, element: "Earth" },
  { id: "Uproot", name: "Uproot", rarity: 3, element: "Earth" },
  { id: "Barricade", name: "Barricade", rarity: 3, element: "Earth" },
  { id: "Splinter", name: "Splinter", rarity: 3, element: "Earth" },

  { id: "Bouldertoss", name: "Bouldertoss", rarity: 2, element: "Earth" },
  { id: "Dig", name: "Dig", rarity: 2, element: "Earth" },
  { id: "Caltrops", name: "Caltrops", rarity: 2, element: "Earth" },
  { id: "Seismic Slam", name: "Seismic Slam", rarity: 2, element: "Earth" },
  { id: "Lifespore", name: "Lifespore", rarity: 2, element: "Earth" },
  { id: "Entomb", name: "Entomb", rarity: 2, element: "Earth" },
  { id: "Entangle", name: "Entangle", rarity: 2, element: "Earth" },
  { id: "Windup Blow", name: "Windup Blow", rarity: 2, element: "Earth" },

  { id: "Boulder Punch", name: "Boulder Punch", rarity: 1, element: "Earth" },
  { id: "Rock Gun", name: "Rock Gun", rarity: 1, element: "Earth" },
  { id: "Giant Swing", name: "Giant Swing", rarity: 1, element: "Earth" },
  { id: "Vinewhip", name: "Vinewhip", rarity: 1, element: "Earth" },
  { id: "Earth Rend", name: "Earth Rend", rarity: 1, element: "Earth" },
  { id: "Roll Out", name: "Roll Out", rarity: 1, element: "Earth" },
  { id: "Rock Armor", name: "Rock Armor", rarity: 1, element: "Earth" },

  { id: "Boltchain", name: "Boltchain", rarity: 5, element: "Air" },
  { id: "Lightning Slash", name: "Lightning Slash", rarity: 5, element: "Air" },
  { id: "Thunder Slash", name: "Thunder Slash", rarity: 5, element: "Air" },
  { id: "Cumulonimbus", name: "Cumulonimbus", rarity: 5, element: "Air" },
  { id: "Volt Cannon", name: "Volt Cannon", rarity: 5, element: "Air" },

  { id: "Clear Sky", name: "Clear Sky", rarity: 4, element: "Air" },
  { id: "Wild Volt", name: "Wild Volt", rarity: 4, element: "Air" },
  { id: "Electrify", name: "Electrify", rarity: 4, element: "Air" },
  { id: "Hurricane", name: "Hurricane", rarity: 4, element: "Air" },
  { id: "Slipstream", name: "Slipstream", rarity: 4, element: "Air" },
  { id: "Cyclone", name: "Cyclone", rarity: 4, element: "Air" },

  { id: "Agility", name: "Agility", rarity: 3, element: "Air" },
  { id: "Wind Blast", name: "Wind Blast", rarity: 3, element: "Air" },
  { id: "Gale Force", name: "Gale Force", rarity: 3, element: "Air" },
  { id: "Tesla Coil", name: "Tesla Coil", rarity: 3, element: "Air" },
  { id: "Rescue", name: "Rescue", rarity: 3, element: "Air" },
  { id: "Charge", name: "Charge", rarity: 3, element: "Air" },
  { id: "Fog Cannon", name: "Fog Cannon", rarity: 3, element: "Air" },
  { id: "Lightning Bolt", name: "Lightning Bolt", rarity: 3, element: "Air" },
  { id: "Skydive", name: "Skydive", rarity: 3, element: "Air" },

  { id: "Aerial Aid", name: "Aerial Aid", rarity: 2, element: "Air" },
  { id: "Pressurize", name: "Pressurize", rarity: 2, element: "Air" },
  { id: "Elevate", name: "Elevate", rarity: 2, element: "Air" },
  { id: "Vacuum", name: "Vacuum", rarity: 2, element: "Air" },
  { id: "Static Shock", name: "Static Shock", rarity: 2, element: "Air" },
  { id: "Storm Fang", name: "Storm Fang", rarity: 2, element: "Air" },
  { id: "Sonic Boom", name: "Sonic Boom", rarity: 2, element: "Air" },
  { id: "Cloud Lance", name: "Cloud Lance", rarity: 2, element: "Air" },

  { id: "Air Rend", name: "Air Rend", rarity: 1, element: "Air" },
  { id: "Thunderpunch", name: "Thunderpunch", rarity: 1, element: "Air" },
  { id: "Gigawing", name: "Gigawing", rarity: 1, element: "Air" },
  { id: "Guiding Bolt", name: "Guiding Bolt", rarity: 1, element: "Air" },
  { id: "Tornado", name: "Tornado", rarity: 1, element: "Air" },
  { id: "Zap", name: "Zap", rarity: 1, element: "Air" },
  { id: "Gust", name: "Gust", rarity: 1, element: "Air" },

  { id: "Cryostasis", name: "Cryostasis", rarity: 5, element: "Water" },
  { id: "Flash Freeze", name: "Flash Freeze", rarity: 5, element: "Water" },
  { id: "Tidal Wave", name: "Tidal Wave", rarity: 5, element: "Water" },
  { id: "Whirlpool", name: "Whirlpool", rarity: 5, element: "Water" },

  { id: "Absolute Zero", name: "Absolute Zero", rarity: 4, element: "Water" },
  { id: "Glacier", name: "Glacier", rarity: 4, element: "Water" },
  { id: "Blizzard", name: "Blizzard", rarity: 4, element: "Water" },
  { id: "Crystallize", name: "Crystallize", rarity: 4, element: "Water" },
  { id: "Harmony", name: "Harmony", rarity: 4, element: "Water" },
  { id: "Avalanche", name: "Avalanche", rarity: 4, element: "Water" },

  { id: "Absorb", name: "Absorb", rarity: 3, element: "Water" },
  { id: "Snowstorm", name: "Snowstorm", rarity: 3, element: "Water" },
  { id: "Curechain", name: "Curechain", rarity: 3, element: "Water" },
  { id: "Calm Mind", name: "Calm Mind", rarity: 3, element: "Water" },
  { id: "Torrent", name: "Torrent", rarity: 3, element: "Water" },
  { id: "Waterwheel", name: "Waterwheel", rarity: 3, element: "Water" },
  { id: "Clairvoyance", name: "Clairvoyance", rarity: 3, element: "Water" },
  { id: "Ice Spikes", name: "Ice Spikes", rarity: 3, element: "Water" },
  { id: "Waterweb", name: "Waterweb", rarity: 3, element: "Water" },

  { id: "Bubble Beam", name: "Bubble Beam", rarity: 2, element: "Water" },
  { id: "Dive", name: "Dive", rarity: 2, element: "Water" },
  { id: "Bubble Shield", name: "Bubble Shield", rarity: 2, element: "Water" },
  { id: "Geyser", name: "Geyser", rarity: 2, element: "Water" },
  { id: "Purify", name: "Purify", rarity: 2, element: "Water" },
  { id: "Liquidate", name: "Liquidate", rarity: 2, element: "Water" },
  { id: "Ice Barrage", name: "Ice Barrage", rarity: 2, element: "Water" },
  { id: "Cure", name: "Cure", rarity: 2, element: "Water" },

  { id: "Black Ice", name: "Black Ice", rarity: 1, element: "Water" },
  { id: "Waterlash", name: "Waterlash", rarity: 1, element: "Water" },
  { id: "Frostbite", name: "Frostbite", rarity: 1, element: "Water" },
  { id: "Ice Fang", name: "Ice Fang", rarity: 1, element: "Water" },
  { id: "Frost Flurry", name: "Frost Flurry", rarity: 1, element: "Water" },
  { id: "Ice Lance", name: "Ice Lance", rarity: 1, element: "Water" },
  { id: "Vapor Slash", name: "Vapor Slash", rarity: 1, element: "Water" },

  { id: "Cataclysm", name: "Cataclysm", rarity: 5, element: "Fire" },
  { id: "Overheat", name: "Overheat", rarity: 5, element: "Fire" },
  { id: "Pyroclasm", name: "Pyroclasm", rarity: 5, element: "Fire" },
  { id: "Inferno", name: "Inferno", rarity: 5, element: "Fire" },

  { id: "Explosion", name: "Explosion", rarity: 4, element: "Fire" },
  { id: "Meteor", name: "Meteor", rarity: 4, element: "Fire" },
  { id: "Pyroblast", name: "Pyroblast", rarity: 4, element: "Fire" },
  { id: "Turmoil", name: "Turmoil", rarity: 4, element: "Fire" },
  { id: "Hell Pyre", name: "Hell Pyre", rarity: 4, element: "Fire" },
  { id: "War Dance", name: "War Dance", rarity: 4, element: "Fire" },

  { id: "Ash Wave", name: "Ash Wave", rarity: 3, element: "Fire" },
  { id: "Lava Flow", name: "Lava Flow", rarity: 3, element: "Fire" },
  { id: "Provoke", name: "Provoke", rarity: 3, element: "Fire" },
  { id: "Berserk", name: "Berserk", rarity: 3, element: "Fire" },
  { id: "Fire Breath", name: "Fire Breath", rarity: 3, element: "Fire" },
  { id: "Eruption", name: "Eruption", rarity: 3, element: "Fire" },
  { id: "Bonfire", name: "Bonfire", rarity: 3, element: "Fire" },
  { id: "Fire Storm", name: "Fire Storm", rarity: 3, element: "Fire" },
  { id: "Hearthstone", name: "Hearthstone", rarity: 3, element: "Fire" },

  { id: "Dragons Rage", name: "Dragons Rage", rarity: 2, element: "Fire" },
  { id: "Kindle", name: "Kindle", rarity: 2, element: "Fire" },
  { id: "Flame Wheel", name: "Flame Wheel", rarity: 2, element: "Fire" },
  { id: "Enrage", name: "Enrage", rarity: 2, element: "Fire" },
  { id: "Heat Up", name: "Heat Up", rarity: 2, element: "Fire" },
  { id: "Ignite", name: "Ignite", rarity: 2, element: "Fire" },
  { id: "Magma Plume", name: "Magma Plume", rarity: 2, element: "Fire" },
  { id: "Fireblast", name: "Fireblast", rarity: 2, element: "Fire" },

  { id: "Combust", name: "Combust", rarity: 1, element: "Fire" },
  { id: "Scorch", name: "Scorch", rarity: 1, element: "Fire" },
  { id: "Flame Whip", name: "Flame Whip", rarity: 1, element: "Fire" },
  { id: "Fire Fang", name: "Fire Fang", rarity: 1, element: "Fire" },
  { id: "Flamethrower", name: "Flamethrower", rarity: 1, element: "Fire" },
  { id: "Fire Bomb", name: "Fire Bomb", rarity: 1, element: "Fire" },
  { id: "Searing Blow", name: "Searing Blow", rarity: 1, element: "Fire" },
];
