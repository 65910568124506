export const labelStyles = {
  py: "7px",
  px: 2,
  borderRadius: "1px",
  userSelect: "none",
};

export const labelFontStyles = {
  fontSize: 15,
  textTransform: "uppercase",
  letterSpacing: "0.5px",
  lineHeight: 1,
  mt: "1px",
};
