import React from "react";
import Value from "./Value";
import Percentage from "./Percentage";
import { Stack } from "@mui/material";

const ValueAndPercentage = ({
  label,
  value,
  percentage,
  style,
  valueStyle,
  percentageStyle,
}) => {
  const traitTextWidth = {
    Element: "75%",
    Card: "83%",
    Body: "83%",
    Eyes: "83%",
    Mouth: "79%",
    Egg: "85%",
    Accessories: "68%",
    Backs: "79%",
    "Face Details": "65%",
    Glasses: "75%",
    Hats: "83%",
    Tails: "82%",
    Wings: "79%",
    Deity: "79%",
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      sx={{
        maxWidth: label in traitTextWidth ? traitTextWidth[label] : "inherit",
        gap: 1,
        ...style,
      }}
    >
      <Value value={value} style={valueStyle} />
      <Percentage percentage={percentage} style={percentageStyle} />
    </Stack>
  );
};

export default ValueAndPercentage;
