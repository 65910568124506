import { Grid, Stack } from "@mui/material";
import React from "react";
import Header from "./Header";
import TraitColumns from "./TraitColumns";
import { connect } from "react-redux";

const Traits = ({ pet, rarity }) => {
  return (
    <Stack gap={2} width="100%">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Header traitCount={pet.traitCount} rarity={rarity} />
        </Grid>
      </Grid>

      <TraitColumns pet={pet} rarity={rarity} />
    </Stack>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({ rarity: state.data.rarity });

export default connect(mapStateToProps, mapDispatchToProps)(Traits);
