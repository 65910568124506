import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import SelectedMoves from "../../../components/SelectedMoves/SelectedMoves";
import { FaSearch } from "react-icons/fa";
import { SEARCH_DIALOG_IS_OPEN } from "../SearchDialog";

const Content = ({
  selectedMoves,
  updateSelectedMoves,
  updateSearchDialog,
}) => {
  const styles = {
    maxHeight: "300px",
    overflow: "auto",
    scrollbarColor: `#eee #ddd`,
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: "#eee",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      backgroundColor: "#ddd",
      border: `1px solid #ddd`,
    },
    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
      backgroundColor: "#C1C1C1",
    },
    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
      backgroundColor: "#C1C1C1",
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#C1C1C1",
    },
    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
      backgroundColor: "#eee",
    },
  };

  return (
    <Stack gap={2} alignItems="flex-end">
      <Typography
        variant="span"
        color="text.dark"
        fontSize={20}
        fontWeight={800}
      >
        {selectedMoves.length} Selected
      </Typography>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={1}
        justifyContent="flex-end"
        sx={styles}
      >
        <SelectedMoves
          selectedMoves={selectedMoves}
          updateSelectedMoves={updateSelectedMoves}
        />
      </Stack>
      <Button
        variant="contained"
        disableElevation
        onClick={() => updateSearchDialog(SEARCH_DIALOG_IS_OPEN, true)}
        startIcon={<FaSearch />}
      >
        Search
      </Button>
    </Stack>
  );
};

export default Content;
