import React, { useEffect, useState } from "react";
import Search from "./Search/Search";
import Result from "./Result";
import { useParams } from "react-router-dom";
import {
  inspectorClear,
  inspectorSearch,
} from "../../store/inspector/inspectorActions";
import { removeNonNumericChars } from "../../helpers/cleaning";
import { connect } from "react-redux";
import Error from "./Error";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import { getPixlPet } from "../../store/inspector/inspectorSelectors";
import SEO from "./Common/SEO";

const Inspector = ({
  inspectorSearch,
  inspectorClear,
  loading,
  pixlPet,
  failed,
}) => {
  const { id } = useParams();
  const [error, setError] = useState(false);
  let content;

  const parseId = (rawId) => {
    let id;
    const numeric = removeNonNumericChars(rawId);

    if (numeric !== "") {
      const idInt = parseInt(numeric);

      if (idInt > 0 && idInt <= 15000) {
        id = idInt;
      } else {
        setError(true);
      }
    }

    return id;
  };

  useEffect(() => {
    setError(false);
    if (id == null) {
      inspectorClear();
    } else {
      const parsed = parseId(id);
      if (parsed != null) {
        inspectorSearch(parsed);
      }
    }
  }, [id, inspectorSearch, inspectorClear]);

  if (error || failed) {
    content = <Error />;
  } else if (pixlPet != null && loading === false) {
    content = <Result />;
  } else if (loading === true) {
    content = <CustomLoader />;
  } else {
    content = <Search />;
  }

  return (
    <>
      <SEO id={id} />
      {content}
    </>
  );
};

const mapDispatchToProps = {
  inspectorSearch,
  inspectorClear,
};

const mapStateToProps = (state) => ({
  loading: state.inspector.loading,
  pixlPet: getPixlPet(state),
  failed: state.inspector.failed,
});

export default connect(mapStateToProps, mapDispatchToProps)(Inspector);
