import React from "react";
import { Snackbar as MuiSnackbar } from "@mui/material";
import Content from "./Content";

const Snackbar = ({
  selectedMoves,
  updateSelectedMoves,
  updateSearchDialog,
}) => {
  return (
    <MuiSnackbar
      open={selectedMoves.length > 0}
      message={
        <Content
          selectedMoves={selectedMoves}
          updateSelectedMoves={updateSelectedMoves}
          updateSearchDialog={updateSearchDialog}
        />
      }
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{
        "& .MuiSnackbarContent-root": {
          minWidth: 220,
          maxWidth: 220,
        },
        "& .MuiSnackbarContent-message": {
          width: "100%",
        },
      }}
    />
  );
};

export default Snackbar;
