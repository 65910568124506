import * as types from "./searchDialogActionTypes";

export const updateSearchDialog = (component, key, value) => ({
  type: types.SEARCH_DIALOG_UPDATE,
  component,
  key,
  value,
});

export const fetchPixlPetPrices = (component, ids) => ({
  type: types.SEARCH_DIALOG_FETCH_PIXL_PET_PRICES,
  component,
  ids,
});

export const fetchPixlPetPricesStarted = (component) => ({
  type: types.SEARCH_DIALOG_FETCH_PIXL_PET_PRICES_STARTED,
  component,
});

export const fetchPixlPetPricesSucceeded = (component) => ({
  type: types.SEARCH_DIALOG_FETCH_PIXL_PET_PRICES_SUCCEEDED,
  component,
});

export const fetchPixlPetPricesFailed = (component) => ({
  type: types.SEARCH_DIALOG_FETCH_PIXL_PET_PRICES_FAILED,
  component,
});

export const fetchAllPixlPetPrices = (component, ids) => ({
  type: types.SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES,
  component,
  ids,
});

export const fetchAllPixlPetPricesStarted = (component) => ({
  type: types.SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES_STARTED,
  component,
});

export const fetchAllPixlPetPricesSucceeded = (component) => ({
  type: types.SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES_SUCCEEDED,
  component,
});

export const fetchAllPixlPetPricesFailed = (component) => ({
  type: types.SEARCH_DIALOG_FETCH_ALL_PIXL_PET_PRICES_FAILED,
  component,
});
