import React from "react";
import { Typography } from "@mui/material";

const Value = ({ value, style }) => {
  return (
    <Typography
      fontWeight={700}
      lineHeight={1}
      sx={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: 15,
        ...style,
      }}
    >
      {value}
    </Typography>
  );
};

export default Value;
