import React from "react";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/system";

const TraitRow = ({ children, style }) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      className="trait"
      sx={{
        "&:hover": {
          cursor: "pointer",
          ".percentage": { color: theme.palette.colors.primary },
        },
        ...style,
      }}
    >
      {children}
    </Stack>
  );
};

export default TraitRow;
