import React from "react";
import { Container, Stack } from "@mui/material";

const PageWrapper = ({ children }) => {
  return (
    <Container sx={{ position: "relative" }}>
      <Stack gap={6} alignItems="flex-start">
        {children}
      </Stack>
    </Container>
  );
};

export default PageWrapper;
