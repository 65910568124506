import React from "react";
import { Stack, Tooltip, Typography } from "@mui/material";

const Price = ({ pixlPet, width }) => {
  return pixlPet?.sellOrderDetails?.amount == null ? (
    <></>
  ) : (
    <Tooltip
      title={
        pixlPet?.sellOrderDetails?.usdAmount != null
          ? `$${pixlPet?.sellOrderDetails?.usdAmount.toLocaleString()}`
          : ""
      }
    >
      <Stack
        direction="row"
        gap={(0.5 / 200) * width}
        alignItems="center"
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
          img: {
            width: (7 / 200) * width,
            height: "auto",
            filter: "brightness(3)",
          },
        }}
      >
        {pixlPet?.sellOrderDetails?.currencyImageUrl && (
          <img
            src={pixlPet?.sellOrderDetails?.currencyImageUrl}
            alt={pixlPet?.sellOrderDetails?.currency}
          />
        )}

        <Typography
          variant="span"
          fontWeight={700}
          fontSize={(13 / 200) * width}
          lineHeight={1}
          color="text.white"
          sx={{ position: "relative", top: `${(1 / 200) * width}px` }}
        >
          {pixlPet?.sellOrderDetails?.amount}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default Price;
