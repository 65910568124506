import React from "react";
import { Box, Container } from "@mui/material";
import { useTheme } from "@mui/system";

const Page = ({ children, container = true, minHeight }) => {
  const theme = useTheme();
  const styles = {
    pt: 12,
    pb: 20,
    minHeight: minHeight ? minHeight : "calc(100vh - 83.2px - 231.34px)",
    [theme.breakpoints.down("sm")]: {
      pb: 8,
      pt: 8,
    },
  };
  return container ? (
    <Container sx={styles}>{children}</Container>
  ) : (
    <Box sx={styles}>{children}</Box>
  );
};

export default Page;
