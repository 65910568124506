import * as types from "./inspectorActionTypes";
import * as actions from "./inspectorActions";
import { takeEvery, select, put, call, take } from "redux-saga/effects";
import { getPixlPets } from "../data/selectors/commonSelectors";
import { fetchPixlPet } from "../data/dataSaga";
import * as dataTypes from "../data/dataActionTypes";

function* inspectorSearch(action) {
  try {
    yield put(actions.inspectorSearchStarted());

    const pixlPetsLoading = yield select(
      (state) => state.data.pixlPets.loading
    );
    if (pixlPetsLoading == null || pixlPetsLoading === true) {
      yield take(dataTypes.DATA_FETCH_PIXL_PETS_SUCCEEDED);
    }

    const pixlPets = yield select(getPixlPets);

    const localPet = pixlPets.find((p) => p.id === action.id);

    // Pet was already in local data
    if (localPet) {
      yield put(actions.inspectorSearchSucceeded(localPet));
    }

    // Fetch pet from API
    else {
      yield call(
        fetchPixlPet,
        action.id,
        actions.inspectorSearchSucceeded,
        actions.inspectorSearchFailed
      );
    }
  } catch (e) {
    console.log(e);
    yield put(actions.inspectorSearchFailed());
  }
}

export default function* inspectorSaga() {
  yield takeEvery(types.INSPECTOR_SEARCH, inspectorSearch);
}
