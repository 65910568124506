import React from "react";
import {
  Select,
  Box,
  Chip,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

const Dropdown = ({
  label,
  value,
  updateValue,
  options,
  size,
  multiple,
  style,
}) => {
  if (multiple) {
    return (
      <MultipleDropdown
        label={label}
        value={value}
        updateValue={updateValue}
        options={options}
        size={size}
        style={style}
      />
    );
  }
  return (
    <SingleDropdown
      label={label}
      value={value}
      updateValue={updateValue}
      options={options}
      size={size}
      style={style}
    />
  );
};

const SingleDropdown = ({
  label,
  value,
  updateValue,
  size = "medium",
  options,
  style,
}) => {
  const labelId = label.toLowerCase() + "-label";
  return (
    <FormControl size={size} sx={style}>
      <InputLabel id={labelId}>{label}</InputLabel>

      <Select
        labelId={labelId}
        label={label}
        value={value}
        onChange={(e) => updateValue(e.target.value)}
      >
        {options.map((item, i) => (
          <MenuItem key={`ITEM_${i}`} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const MultipleDropdown = ({
  label,
  value,
  updateValue,
  size,
  options,
  style,
}) => {
  const labelId = label.toLowerCase() + "-label";
  return (
    <FormControl size={size} sx={style}>
      <InputLabel id={labelId}>{label}</InputLabel>

      <Select
        multiple
        labelId={labelId}
        label={label}
        value={value}
        onChange={(e) => updateValue(e.target.value)}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((i) => (
              <Chip
                color="primary"
                key={i}
                label={i}
                size="small"
                sx={{ height: "auto" }}
              />
            ))}
          </Box>
        )}
      >
        {options.map((item, i) => (
          <MenuItem key={`ITEM_${i}`} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
