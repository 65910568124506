import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import Image from "./Image/Image";
import Traits from "./Traits/Column/Traits";
import { connect } from "react-redux";
import Price from "./Price/Price";

const PixlPet = ({
  pixlPet,
  width = 240,
  showTraits,
  rarity,
  showName = true,
  showPrice = true,
  hoverEffect = true,
  gutter = true,
}) => {
  const theme = useTheme();
  if (pixlPet == null) {
    return null;
  }
  const wrapperStyles = gutter
    ? {
        background: theme.palette.colors.black800,
        p: `${(8 / 200) * width}px`,
        borderRadius: `${(8 / 200) * width}px`,
      }
    : {};

  return (
    <Stack gap={1} sx={wrapperStyles}>
      <Image pixlPet={pixlPet} width={width} hoverEffect={hoverEffect} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {showName && (
          <Typography variant="span" fontSize={(12 / 200) * width}>
            {pixlPet.name}
          </Typography>
        )}
        {showPrice && <Price pixlPet={pixlPet} width={width} />}
      </Stack>
      <Traits
        width={width}
        traits={pixlPet.traits}
        rarity={rarity}
        showTraits={showTraits}
        traitCount={pixlPet.traitCount}
      />
    </Stack>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({ rarity: state.data.rarity });

export default connect(mapStateToProps, mapDispatchToProps)(PixlPet);
