import { Stack, Typography } from "@mui/material";
import React from "react";
import { labelStyles, labelFontStyles } from "./commonStyles";
import { keyframes } from "@emotion/react";

const Deity = () => {
  const outerWrapperStyles = {
    animation: `${animateBoxShadow} ease 4s infinite`,
    padding: "1px",
    background: "linear-gradient(100deg, #FBE6FF 0%, #E9FFFF 100.2%)",
  };
  const wrapperStyles = {
    animation: `${animateGradient} ease 4s infinite`,
    backgroundSize: "400% 400%",
    backgroundImage:
      "linear-gradient(86.31deg, #F8CBFF 0%, #CDDEFF 50.1%, #B0FFFF 100.2%)",
  };

  return (
    <Stack sx={outerWrapperStyles}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ ...labelStyles, ...wrapperStyles }}
      >
        <Typography
          variant="span"
          color="text.dark"
          fontWeight={700}
          sx={{ ...labelFontStyles }}
        >
          Deity
        </Typography>
      </Stack>
    </Stack>
  );
};

const animateGradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}`;

const animateBoxShadow = keyframes`
  0% {
    box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.78);
  }
  50% {
    box-shadow: 0px 0px 7px rgba(255, 255, 255, 0.78);
  }
  100% {
    box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.78);
  }
}`;

export default Deity;
