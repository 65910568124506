import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import { labelStyles, labelFontStyles } from "./commonStyles";
import { keyframes } from "@emotion/react";

const Card = ({ card }) => {
  const theme = useTheme();
  let wrapperStyles = {};
  let textStyles = {};
  let outerWrapperStyles = {};

  if (card === "Silver") {
    outerWrapperStyles = {
      padding: "1px",
      background:
        "linear-gradient(0deg, #9FB1C2 0%, #E1E5EE 72.39%, #FFFFFF 99.99%)",
    };
    wrapperStyles = {
      background:
        "linear-gradient(90deg, #9FB1C2 0%, #E1E5EE 72.39%, #FFFFFF 99.99%)",
    };
  } else if (card === "Gold") {
    outerWrapperStyles = {
      padding: "1px",
      background: "linear-gradient(0deg, #E49900 0%, #FFFA63 100.14%)",
    };
    wrapperStyles = {
      background: "linear-gradient(90deg, #E49900 0%, #FFFA63 100.14%)",
    };
  } else if (card === "Prismatic") {
    outerWrapperStyles = {
      padding: "1px",
      animation: `${animateGradient} ease 10s infinite`,
      backgroundSize: "400% 400%",
      backgroundImage:
        "linear-gradient(45deg, #FF5B58 0%, #F0F359 20%, #BFFD65 40%, #6AE5ED 60%, #F296E4 80%, #D14FF7 100%)",
    };
    wrapperStyles = {
      animation: `${animateGradient} ease 10s infinite`,
      backgroundSize: "400% 400%",
      backgroundImage:
        "linear-gradient(90deg, #FF5B58 0%, #F0F359 20%, #BFFD65 40%, #6AE5ED 60%, #F296E4 80%, #D14FF7 100%)",
    };
  } else if (card === "Holo") {
    outerWrapperStyles = {
      animation: `${animateBoxShadow} ease 4s infinite`,
      padding: "1px",
      background:
        "linear-gradient(0deg, #E9E9E9 0%, #FFFFFF 12%, #EBFFFE 25.4%, #FFFFFF 48.01%, #EDEDED 58.97%, #FEFAFF 67.32%, #FFFFFF 78.28%, #F3FFFF 90.29%)",
    };
    wrapperStyles = {
      animation: `${animateGradient} ease 4s infinite`,
      backgroundSize: "400% 400%",
      backgroundImage:
        "linear-gradient(90deg, #D3D3D3 0%, #FFFFFF 12%, #EBFFFE 25.4%, #FFFFFF 58.97%, #FEFAFF 67.32%, #FFFFFF 78.28%, #FFFEEB 90.29%, #F8EEFF 100%)",
    };
  } else {
    wrapperStyles = { border: "1px solid #fff" };
    textStyles = { color: theme.palette.colors.white };
  }

  return (
    <Stack sx={outerWrapperStyles}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ ...labelStyles, ...wrapperStyles }}
      >
        <Typography
          variant="span"
          color="text.dark"
          fontWeight={700}
          sx={{ ...labelFontStyles, ...textStyles }}
        >
          {`${card} Card`}
        </Typography>
      </Stack>
    </Stack>
  );
};

const animateGradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}`;

const animateBoxShadow = keyframes`
  0% {
    box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.78);
  }
  50% {
    box-shadow: 0px 0px 7px rgba(255, 255, 255, 0.78);
  }
  100% {
    box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.78);
  }
}`;

export default Card;
