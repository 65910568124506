import { createSelector } from "reselect";
import { augmentPet } from "../../helpers/pixlPets";
import {
  getMoves,
  getRarity,
  getPixlPets,
} from "../data/selectors/commonSelectors";
import { augmentMove } from "../../helpers/moves";
import { getSearchDialogData } from "../searchDialog/search";

const getPixlPetRaw = (state) => {
  return state.inspector.pixlPet;
};

const getSelectedMoves = (state) => {
  return state.inspector.selectedMoves;
};

export const getSearchDialog = (state) => {
  return state.searchDialog.inspector;
};

export const getPixlPet = createSelector(
  [getPixlPetRaw, getMoves, getRarity],
  (pixlPet, allMoves, rarity) => {
    if (pixlPet == null) {
      return undefined;
    }

    return augmentPet(pixlPet, allMoves, rarity);
  }
);

export const getSelectedMoveData = createSelector(
  [getSelectedMoves, getMoves],
  (selectedMoves, allMoves) => {
    return selectedMoves.map((move) => augmentMove(move, allMoves));
  }
);

export const getSearchResults = createSelector(
  [getPixlPets, getMoves, getRarity, getSelectedMoves, getSearchDialog],
  (pixlPets, allMoves, rarity, selectedMoves, searchDialog) => {
    return getSearchDialogData(
      pixlPets,
      allMoves,
      rarity,
      selectedMoves,
      searchDialog
    );
  }
);
