import React from "react";
import Helmet from "react-helmet";

const SEO = (props) => {
  const title = "Pixlverse.Tech";
  const description =
    "Pixlverse.Tech, the number 1 Pixlverse Fan site, offers tools & resources for gamers & managers.";
  const url = "https://pixlverse.tech";
  const siteName = "Pixlverse.Tech";

  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="pixlverse tech, pixlverse.tech, pixlverse, move explorer, pixlverse pricing, pixlverse breeding, pixlverse managed scholarships, play to earn, play2earn, crypto gaming"
      />
      {/* OpenGraph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content="https://pixlverse.tech/images/social/facebook.jpg"
      />
      <meta property="og:site_name" content={siteName} />
      {/* Twitter Card tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content="https://pixlverse.tech/images/social/twitter.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@PixlverseTech" />
      <meta name="twitter:creator" content="@PixlverseTech" />
    </Helmet>
  );
};

export default SEO;
