import { Typography } from "@mui/material";
import React from "react";

const Label = ({ children, style }) => {
  return (
    <Typography
      variant="span"
      textTransform="uppercase"
      color="text.muted"
      fontSize={13}
      sx={{ whiteSpace: "nowrap", ...style }}
    >
      {children}
    </Typography>
  );
};

export default Label;
