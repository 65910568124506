import { getTrait } from "./pixlPets";

export const petShouldBeAddedToDatabase = (pet) => {
  const eggTrait = getTrait(pet, "Egg");
  if (eggTrait !== "Hatched") {
    return false;
  }
  return true;
};

export const cleanPet = (data) => {
  const id = data?.edition;
  const name = data?.name;
  const attributes = data?.attributes;
  const image = data?.image;

  const formattedAttributes =
    attributes &&
    attributes.reduce((prev, curr) => {
      if ("trait_type" in curr) {
        prev.push({
          traitType: curr.trait_type,
          value: curr.value,
        });
      }
      return prev;
    }, []);

  return {
    id,
    name,
    attributes: formattedAttributes,
    image,
  };
};
