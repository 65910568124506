import {
  OPENSEA_API_ORDERS_ENDPOINT,
  PIXLVERSE_API,
  PIXLVERSE_TECH_API,
  PIXL_PETS_CONTRACT_ADDRESS,
} from "../config";

export const pixlPetsEndpoint = PIXLVERSE_TECH_API;

export const makePixlPetEndpoint = (id) => `${PIXLVERSE_API}/pixlpets/${id}`;

export const makePixlPetPriceEndpoint = (ids) => {
  let url = `${OPENSEA_API_ORDERS_ENDPOINT}?asset_contract_address=${PIXL_PETS_CONTRACT_ADDRESS}&bundled=false&include_bundled=false&side=1`;

  for (const id of ids) {
    url += `&token_ids=${id}`;
  }
  return url;
};
