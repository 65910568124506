import { Stack, Typography } from "@mui/material";
import CustomLink from "components/CustomLink/CustomLink";
import React from "react";
import Copyright from "./Copyright";
import TriballyBadge from "components/TriballyBadge/TriballyBadge";
import { TRIBALLY_TAGLINE } from "config";

const Footer = ({ position = "static" }) => {
  let styles;
  if (position === "fixed") {
    styles = { position: "fixed", bottom: 0, right: 0, userSelect: "none" };
  } else {
    styles = { width: 1100, ml: "auto", userSelect: "none" };
  }

  return (
    <Stack
      gap={1}
      alignItems="center"
      sx={{
        overflow: "hidden",
        img: styles,
      }}
    >
      <Copyright />

      <Stack direction="row" alignItems="center" gap={2}>
        <CustomLink to="/terms-and-conditions">
          <Typography
            fontSize={11}
            sx={{ opacity: 0.3, "&:hover": { opacity: 1 } }}
          >
            Terms and Conditions
          </Typography>
        </CustomLink>
        <CustomLink to="/privacy-policy">
          <Typography
            fontSize={11}
            sx={{ opacity: 0.3, "&:hover": { opacity: 1 } }}
          >
            Privacy Policy
          </Typography>
        </CustomLink>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
        marginTop={7}
      >
        <TriballyBadge
          width={250}
          maxWidth={250}
          title={TRIBALLY_TAGLINE}
          alt={TRIBALLY_TAGLINE}
        />
      </Stack>

      <img src="/images/footer-background.png" alt="Pixelated landscape" />
    </Stack>
  );
};

export default Footer;
