import React from "react";
import PixlPet from "../../../components/PixlPet/PixlPet";
import Name from "../Common/Name";
import { Stack, useMediaQuery } from "@mui/material";
import Traits from "../../../components/PixlPet/Traits/SideBySide/Traits";
import Labels from "../Common/Labels";
import { useTheme } from "@mui/system";
import Moves from "../../../components/PixlPet/Moves/Moves";
import OpenSeaButton from "../Common/OpenSeaButton";
import Price from "../Common/Price";

const Adult = ({ pet, selectedMoves, updateSelectedMoves }) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack gap={6} width="100%">
      <Stack gap={{ xs: 2, sm: 2, md: 1 }}>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          flexWrap={{ xs: "wrap", sm: "wrap", md: "nowrap" }}
        >
          <Name name={pet.name} />
          <OpenSeaButton url={pet.opensea} />
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          <Labels
            element={pet.traits.Element}
            card={pet.traits.Card}
            traitCount={pet.traitCount}
            deity={pet.isDeity}
          />
          <Price pet={pet} />
        </Stack>
      </Stack>

      <Stack
        gap={4}
        direction={{ xs: "column", sm: "column", md: "row" }}
        alignItems={{ xs: "center", sm: "center", md: "flex-start" }}
      >
        <PixlPet
          pixlPet={pet}
          showName={false}
          showPrice={false}
          hoverEffect={false}
          showTraits={false}
          gutter={false}
          width={mdDown ? 300 : 380}
        />
        <Stack gap={3} width="100%">
          <Moves
            pet={pet}
            selectedMoves={selectedMoves}
            updateSelectedMoves={updateSelectedMoves}
          />
          <Traits pet={pet} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Adult;
