import React, { useState } from "react";
import { Button, Stack, TextField, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";

const SearchBox = () => {
  const [id, setId] = useState("");
  const history = useNavigate();

  const handleSearch = () => {
    history(`/inspector/${id}`);
  };

  const handleKeypress = (e) => {
    if (e.which === 13) {
      handleSearch();
    }
  };

  const handleChange = (newValue) => {
    if (newValue < 0) {
      setId(0);
    } else {
      setId(newValue);
    }
  };

  return (
    <form onKeyPress={handleKeypress}>
      <Stack direction="row" flexWrap="wrap" gap={1}>
        <TextField
          placeholder="Pixl Pet ID"
          variant="outlined"
          spellCheck={false}
          onChange={(e) => handleChange(e.target.value)}
          onWheel={(e) => e.target.blur()}
          value={id}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FaSearch />
              </InputAdornment>
            ),
          }}
          sx={{
            width: 180,
          }}
          autoComplete="off"
        />
        <Button variant="contained" onClick={handleSearch} size="large">
          Search
        </Button>
      </Stack>
    </form>
  );
};

export default SearchBox;
