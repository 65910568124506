import { IconButton, Stack } from "@mui/material";
import React from "react";
import { FaTwitter } from "react-icons/fa";
import { PIXLVERSE_TECH_TWITTER } from "../../config";

const Socials = () => {
  return (
    <Stack>
      <IconButton href={PIXLVERSE_TECH_TWITTER} target="_blank" color="white">
        <FaTwitter />
      </IconButton>
    </Stack>
  );
};

export default Socials;
